import { Button, Form, Modal, Input, Typography, message } from 'antd';
import React, { useContext, useState } from 'react';

import { BreweryContext } from '../../context';
import httpCommon from '../../http-common';

export default function HelpModal({ isHelpModalOpen, handleHelpModal }) {
  const [brewery] = useContext(BreweryContext);

  const [messageApi, contextHolder] = message.useMessage();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = Form.useForm();

  const handleFinish = async (values) => {
    try {
      setIsSubmitting(true);

      let data = {
        email: brewery.fields['Public Email'],
        message: values.message,
        subject: values.subject,
      };

      await httpCommon.post('/email/help', data);

      messageApi.success('Sent. We will contact you soon!');

      form.resetFields();
      handleHelpModal();
    } catch (e) {
      console.log(e);
      messageApi.error('Error request. Please, check your connection and try again!');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Modal
        open={isHelpModalOpen}
        onCancel={handleHelpModal}
        title="Help"
        footer={[
          <Button disabled={isSubmitting} loading={isSubmitting} onClick={() => form.submit()}>
            Send
          </Button>,
        ]}
      >
        <Form form={form} onFinish={handleFinish}>
          <Typography.Paragraph>
            Do you need some help? Fill out the form and we'll get back to you as soon as possible.
          </Typography.Paragraph>

          <Form.Item name="subject" rules={[{ required: true }]}>
            <Input disabled={isSubmitting} placeholder="Subject" />
          </Form.Item>

          <Form.Item name="message" rules={[{ required: true }]}>
            <Input.TextArea maxLength={500} showCount disabled={isSubmitting} placeholder="Message" />
          </Form.Item>
        </Form>
      </Modal>

      {contextHolder}
    </>
  );
}
