import { Space, Row, Col } from 'antd';

export default function BlockSlider({ title, children, score }) {
  return (
    <Col xs={24} lg={8} style={{ marginBottom: 10 }}>
      <Space>
        <h3 className="title">{title}</h3>
      </Space>

      <div className="form__section__slider w-full">
        <Row gutter={1}>
          <Col xs={21} md={22} lg={19} style={{ paddingLeft: 2 }}>
            {children}
          </Col>
          <Col xs={3} md={2} lg={5}>
            <div className="form__section__slider_item">
              <div>
                <p>{score}</p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Col>
  );
}
