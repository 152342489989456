import { SET_ALL_DATA_FORM } from "../actions/types";

const initialState = {
  judgingForm: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState.judgingForm, action) {
  switch (action.type) {
    case SET_ALL_DATA_FORM:
      return (initialState.judgingForm = action.payload);

    default:
      return state;
  }
}
