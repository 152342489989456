import { Button, QRCode, Space, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { airtableURL } from '../../FetchURL';
import http from '../../http-common';

export function ViewTicketPage() {
  const { ticketId } = useParams();
  const [scanUrl, setscanUrl] = useState('');
  useEffect(() => {
    http.get(airtableURL + `/scan-url/${ticketId}`).then((res) => {
      setscanUrl(res.data);
    });
  }, [ticketId]);

  return (
    <Space
      direction="vertical"
      size="middle"
      align="center"
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: '80%',
      }}
    >
      <img
        src="https://ontariobrewingawards.com/wp-content/uploads/2020/09/cropped-OBA_logo_FINAL-1-1.png"
        alt="logo"
        style={{
          width: '150px',
        }}
      />
      <Typography.Paragraph style={{ textAlign: 'center', fontSize: 10 }}>
        Dec 5, 2023, 4:30 PM
        <br />
        Student Centre NCSAC Armoury
        <br />
        135 Taylor Road
        <br />
        Niagara-on-the-Lake, ON L0S 1J0
      </Typography.Paragraph>
      <QRCode value={scanUrl} />
      <Link to={`/ticket-registration/${process.env.REACT_APP_EVENT_ID}?tab=2`}>
        <Button className="checkout-button">Back to My Tickets</Button>
      </Link>
    </Space>
  );
}
