import { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Table, Space, Row, Button } from 'antd';

import { airtableURL } from '../../FetchURL';
import http from '../../http-common';
import { EntriesContext, BreweryContext, UserContext } from '../../context';
import useLogOut from '../../hooks/useLogOut';

import Loading from '../Loading';

import './index.scss';

const EventsPage = () => {
  const navigate = useNavigate();
  const [user] = useContext(UserContext);
  const [entries, setEntries] = useContext(EntriesContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useLogOut();

  console.log('entries', entries);

  const columns = [
    {
      title: 'EVENT NAME',
      dataIndex: 'event_name',
      key: 'event_name',
      render: (text) => {
        return <span>{text}</span>;
      },
    },
    {
      title: 'SUBMISSION START',
      dataIndex: 'submission_start',
      key: 'submission_start',
    },
    {
      title: 'SUBMISSION END',
      dataIndex: 'submission_end',
      key: 'submission_end',
    },
    // {
    //   title: 'JUDGING DATE',
    //   dataIndex: 'judging_date',
    //   key: 'judging_date',
    // },
    {
      title: '',
      key: '',
      render: (_, record) => (
        <Space
          size="middle"
          onClick={() => {
            setEntries((prevState) => ({
              ...prevState,
              eventName: record.event_name,
              data: record.entries_data,
              id: record.id,
              price: record.entries_price,
            }));
            navigate(`/entries/${record.id}`);
          }}
        >
          <a>Add/Edit Entries</a>
        </Space>
      ),
    },
    {
      title: '',
      key: '',
      render: (_, record) => {
        // if (brewery.fields['Public Email'] === 'romaan@thehold.co') {
        return (
          <Space
            size="middle"
            onClick={() => {
              console.log('record', record);
              setEntries((prevState) => ({
                ...prevState,
                eventName: record.event_name,
                data: record.entries_data,
                id: record.id,
                price: record.entries_price,
              }));
              navigate(`/ticket-registration/${record.id}`);
            }}
          >
            <button className="checkout-button">Buy Tickets</button>
          </Space>
        );
        // }
      },
    },
  ];

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        setLoading(true);
        const res = await http.get(airtableURL + '/get-events');
        if (res.status !== 200) return setLoading(false);
        setData(res.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error('There was an error fetch auth', error);
        return;
      }
    };

    fetchEventData();
  }, []);

  return (
    <div className="custom-container">
      <div className="custom-label__header">
        <label>Events</label>
      </div>
      <div className="events">
        {loading ? (
          <Loading />
        ) : (
          <>
            {user.isAdmin && (
              <Row justify="end">
                <Link to="/admin-assign">
                  <Button className="checkout-button" style={{ marginBottom: 10 }}>
                    Admin Assign Tickets
                  </Button>
                </Link>
              </Row>
            )}
            <Table className="events-table" columns={columns} dataSource={data} />
          </>
        )}
      </div>
    </div>
  );
};

export default EventsPage;
