import { Modal, message } from 'antd';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';

import CustomButton from '../CustomButton';
import FormInput from '../FormInput';
import Loading from '../Loading';

import { authURL } from '../../FetchURL';
import http from '../../http-common';

import './index.scss';

const ForgotPasswordModal = (props) => {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: async (values) => {
      setLoading(true);
      formik.setSubmitting(false);

      try {
        await http.get(authURL + `/recover-password/${values.email.toLowerCase()}`);
        setLoading(false);
        message.success('Email Sent');
      } catch (error) {
        setLoading(false);
        message.error('Failed');
        // setErrorMessage("Authentication Failed! Please try again");
      }
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required('Required'),
    }),
  });

  return (
    <>
      <Modal
        title="Recovery Password Send"
        open={props.isModalOpen}
        onOk={props.handleOk}
        onCancel={props.handleCancel}
        className="modal"
        footer={[
          <div key="Email"></div>,
          <CustomButton primary="primary" onClick={formik.handleSubmit} loading={loading} label="Send" key="submit" />,
        ]}
      >
        {loading ? (
          <div style={{ height: '300px' }}>
            <Loading />
          </div>
        ) : (
          <>
            <div style={{ marginBottom: '20px' }}>
              <label>
                Enter your email and we’ll send you a link to reset your password. Kindly check your spam if you did not
                find the email in the inbox{' '}
              </label>
            </div>
            <FormInput
              id="email"
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              placeHolder="Enter Email"
            />
            {formik.errors.email && formik.touched.email && (
              <div className="custom__input-error">{formik.errors.email}</div>
            )}
          </>
        )}
      </Modal>
    </>
  );
};

export default ForgotPasswordModal;
