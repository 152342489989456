import { Modal, Form, Input, Button, Row } from 'antd';
import { useState } from 'react';

import { airtableURL } from '../../FetchURL';
import http from '../../http-common';
import './index.scss';

export default function AssignTicketModal(props) {
  const [submissionLoading, setSubmissionLoading] = useState(false);

  const onFinish = (values) => {
    setSubmissionLoading(true);
    
    http
      .post(airtableURL + '/assign-ticket', {
        ticketID: props?.ticket?.id,
        assigneeName: values.name,
        email: values.email,
      })
      .then(() => {
        setSubmissionLoading(false);
        props.onOk();
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    // props.onCancel();
  };

  return (
    <Modal
      title="Assign Ticket"
      open={props.isModalOpen}
      onOk={props.onOk}
      onCancel={props.onCancel}
      footer={[]}
      destroyOnClose={props.destroyOnClose || false}
    >
      {/* <p>{props?.ticket?.id}</p> */}
      <Form
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Please input a name',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              type: 'email',
              required: true,
              message: 'Please input an email',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Row justify={'center'}>
            <Button type="primary" className="checkout-button" htmlType="submit" loading={submissionLoading}>
              Confirm Assign
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
}
