import { Button, Col, Input, Row, Select, Table, notification } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useState } from 'react';

import AssignTicketModal from '../AssignTicketModal';
import { airtableURL } from '../../FetchURL';
import http from '../../http-common';
import './index.scss';

export default function AdminAssign() {
  const [api, contextHolder] = notification.useNotification();

  const [modalTicket, setmodalTicket] = useState(false);
  const [isAssigned, setIsAssigned] = useState(false);
  const [searchText, setsearchText] = useState('');
  const [loading, setLoading] = useState(false);
  const [tickets, setTickets] = useState([]);

  const onSearch = () => {
    setLoading(true);

    http
      .get(airtableURL + `/search-tickets?search=${searchText}&isAssigned=${isAssigned}`)
      .then((response) => {
        setLoading(false);
        setTickets(response.data);
      })
      .catch((error) => {
        setLoading(false);
        api.error({
          message: error?.response?.data || 'Something went wrong when attempting to send the email',
          placement: 'topRight',
        });
      });
  };

  const handleEmailResend = (ticket) => {
    setLoading(true);

    http
      .post(`airtable/resend-email/${ticket.id}`)
      .then(() => {
        setLoading(false);
        api.success({ message: `Email sent to the ${ticket.assigneeEmail}!`, placement: 'topRight' });
      })
      .catch((error) => {
        setLoading(false);
        api.error({
          message: error?.response?.data || 'Something went wrong when attempting to send the email',
          placement: 'topRight',
        });
      });
  };
  const selectAfter = (
    <Select defaultValue={isAssigned} onChange={(value) => setIsAssigned(value)}>
      <Select.Option value={false}>Not Assigned</Select.Option>
      <Select.Option value={true}>Assigned</Select.Option>
    </Select>
  );

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Buyer Email',
      dataIndex: 'attendeeEmail',
      key: 'attendeeEmail',
    },
    {
      title: 'Buyer Name',
      dataIndex: 'attendeeName',
      key: 'attendeeName',
    },
    {
      title: 'Attendant Email',
      dataIndex: 'assigneeEmail',
      key: 'assigneeEmail',
    },
    {
      title: 'Attendant Type',
      dataIndex: 'ticketType',
      key: 'ticketType',
    },
    {
      title: 'Checked In',
      dataIndex: 'isCheckedIn',
      key: 'isCheckedIn',
      render: (value) => {
        return (
          <Row justify="center">
            {value ? (
              <CheckCircleOutlined style={{ color: 'green' }} />
            ) : (
              <CloseCircleOutlined style={{ color: 'red' }} />
            )}
          </Row>
        );
      },
    },
    {
      title: 'Action',
      render: (_, record) => {
        return (
          <Row gutter={10}>
            <Col>
              <Button className="checkout-button" onClick={() => setmodalTicket(record)}>
                Assign
              </Button>
            </Col>
            <Col>
              <Button onClick={() => handleEmailResend(record)}>Send Ticket</Button>
            </Col>
          </Row>
        );
      },
    },
  ];

  const handleOk = () => {
    onSearch();
    setmodalTicket(false);
  };

  return (
    <>
      <div className="custom-container">
        <div className="custom-label__header">
          <label>Assign Tickets</label>
        </div>

        <div className="content">
          <Input.Search
            onSearch={onSearch}
            onChange={(event) => setsearchText(event.target.value)}
            placeholder="Type an email or record ID"
            enterButton="Search"
            size="large"
            addonBefore={selectAfter}
            style={{ marginBottom: 20 }}
          />
          <Table dataSource={tickets} columns={columns} rowKey="id" loading={loading} />
        </div>
      </div>

      <AssignTicketModal
        isModalOpen={modalTicket}
        onOk={handleOk}
        onCancel={() => setmodalTicket(false)}
        ticket={modalTicket}
        destroyOnClose={true}
      />

      {contextHolder}
    </>
  );
}
