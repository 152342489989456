import { useContext, useEffect, useState } from 'react';
import { Space, Select, Divider, Row, Col, Input, Form, Button, Layout } from 'antd';

import './index.scss';
import http from '../../http-common';
import { stripeURL } from '../../FetchURL';

const TicketQuickBuyPage = () => {
  const [conferenceQuantity, setConferenceQuantity] = useState(1);
  const [earlyQuantity, setEarlyQuantity] = useState(0);
  const [galaQuantity, setGalaQuantity] = useState(0);
  const [exhibitionQuantity, setExhibitionQuantity] = useState(0);

  const handleConferenceTicketChange = (value) => {
    setConferenceQuantity(value);
  };
  const handleGalaChange = (value) => {
    setGalaQuantity(value);
  };
  const handleExhibitionChange = (value) => {
    setExhibitionQuantity(value);
  };

  const onFinish = (values) => {
    http
      .post(stripeURL + '/create-checkout-session?returnUrl=true', {
        conferenceQuantity: conferenceQuantity,
        quantityGala: galaQuantity,
        quantityExhibition: exhibitionQuantity,
        //TODO Required fields
        eventId: process.env.REACT_APP_EVENT_ID,
        company: values.company,
        assigneeData: values,
      })
      .then((response) => {
        window.location.href = response.data;
        console.log('success', response.data);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const renderTicketAssigns = (type, quantity) => {
    let tickets = [];

    for (let i = 0; i < quantity; i++) {
      tickets.push(
        <>
          <>
            <Form.Item
              style={{ marginBottom: 4 }}
              name={`${type}_assignee_name_${i}`}
              rules={[{ required: true, message: 'Please assign the ticket to someone' }]}
            >
              <Input placeholder={`Assignee ${i + 1} Name`} />
            </Form.Item>
            <Form.Item
              style={{ marginBottom: 4 }}
              name={`${type}_assignee_email_${i}`}
              rules={[{ required: true, message: 'Please enter the assignee email.', type: 'email' }]}
            >
              <Input placeholder={`Assignee ${i + 1} Email`} />
            </Form.Item>
          </>
          <br />
        </>
      );
    }

    return tickets;
  };

  return (
    <div className="quick-buy-custom-container">
      <div className="custom-label__header">
        <img
          className="cbac-logo"
          src="https://ontariobrewingawards.com/wp-content/uploads/2020/09/cropped-OBA_logo_FINAL-1-1.png"
          alt="logo"
        />
      </div>
      <div className="quick-buy-label__header">
        <label>{process.env.REACT_APP_EVENT_NAME_ABRV} Ticket Registration - Quick Purchase</label>
      </div>
      <div className="content">
        <Form name="assign_conference" onFinish={onFinish}>
          <Form.Item
            label="Company Name"
            name="company"
            rules={[{ required: true, message: 'Please enter your company name.' }]}
            style={{ width: '33%', marginBottom: '8%' }}
          >
            <Input placeholder="Company" />
          </Form.Item>
          <div className="ticket-line-item">
            <Row>
              <Col span={18}>
                <b>{process.env.REACT_APP_EVENT_NAME_ABRV} Conference Ticket</b>
                <br />
                <b>$400.00</b>
                <p>Full access to the entire 3-day event.</p>
              </Col>
              <Col span={6}>
                <Select
                  placeholder="Select quantity"
                  style={{ width: 80 }}
                  onChange={handleConferenceTicketChange}
                  defaultValue={1}
                >
                  <Select.Option value={0}>0</Select.Option>
                  <Select.Option value={1}>1</Select.Option>
                  <Select.Option value={2}>2</Select.Option>
                  <Select.Option value={3}>3</Select.Option>
                  <Select.Option value={4}>4</Select.Option>
                  <Select.Option value={5}>5</Select.Option>
                  <Select.Option value={6}>6</Select.Option>
                  <Select.Option value={7}>7</Select.Option>
                  <Select.Option value={8}>8</Select.Option>
                  <Select.Option value={9}>9</Select.Option>
                  <Select.Option value={10}>10</Select.Option>
                </Select>
              </Col>

              <Space direction="vertical">{renderTicketAssigns('conference', conferenceQuantity)}</Space>
            </Row>
            <Divider />
          </div>

          <div className="ticket-line-item">
            <Row>
              <Col span={18}>
                <b>Gala Only Registration</b>
                <br />
                <b>$200.00</b>
                <p>Attend the gala on Saturday night.</p>
              </Col>
              <Col span={6}>
                <Select
                  placeholder="Select quantity"
                  style={{ width: 80 }}
                  onChange={handleGalaChange}
                  defaultValue={0}
                >
                  <Select.Option value={0}>0</Select.Option>
                  <Select.Option value={1}>1</Select.Option>
                  <Select.Option value={2}>2</Select.Option>
                  <Select.Option value={3}>3</Select.Option>
                  <Select.Option value={4}>4</Select.Option>
                  <Select.Option value={5}>5</Select.Option>
                  <Select.Option value={6}>6</Select.Option>
                  <Select.Option value={7}>7</Select.Option>
                  <Select.Option value={8}>8</Select.Option>
                  <Select.Option value={9}>9</Select.Option>
                  <Select.Option value={10}>10</Select.Option>
                </Select>
              </Col>

              <Space direction="vertical">{renderTicketAssigns('gala', galaQuantity)}</Space>
            </Row>
            <Divider />
          </div>

          <div className="ticket-line-item">
            <Row>
              <Col span={18}>
                <b>Exhibition Only Registration</b>
                <br />
                <b>$200.00</b>
                <p>
                  This ticket grants you access to the exhibition floor, information sessions, and evening parties. It
                  does not include the Gala.
                </p>
              </Col>
              <Col span={6}>
                <Select
                  placeholder="Select quantity"
                  style={{ width: 80 }}
                  onChange={handleExhibitionChange}
                  defaultValue={0}
                >
                  <Select.Option value={0}>0</Select.Option>
                  <Select.Option value={1}>1</Select.Option>
                  <Select.Option value={2}>2</Select.Option>
                  <Select.Option value={3}>3</Select.Option>
                  <Select.Option value={4}>4</Select.Option>
                  <Select.Option value={5}>5</Select.Option>
                  <Select.Option value={6}>6</Select.Option>
                  <Select.Option value={7}>7</Select.Option>
                  <Select.Option value={8}>8</Select.Option>
                  <Select.Option value={9}>9</Select.Option>
                  <Select.Option value={10}>10</Select.Option>
                </Select>
              </Col>

              <Space direction="vertical">{renderTicketAssigns('exhibition', exhibitionQuantity)}</Space>
            </Row>
            <Divider />
          </div>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ backgroundColor: 'rgb(166, 15, 27)' }}>
              Purchase Tickets
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default TicketQuickBuyPage;
