import { Input } from 'antd';
import './index.scss';

const FormInput = (props) => {
  return (
    <>
      {props.password === 'password' ? (
        <Input.Password
          className="custom-input"
          id={props.id}
          name={props.name}
          onChange={props.onChange}
          value={props.value}
          placeholder={props.placeHolder}
          disabled={props.disabled}
        />
      ) : (
        <Input
          rules={[{ required: true }]}
          className="custom-input"
          id={props.id}
          name={props.name}
          onChange={props.onChange}
          value={props.value}
          placeholder={props.placeHolder}
          disabled={props.disabled}
        />
      )}
    </>
  );
};

export default FormInput;
