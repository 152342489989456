import { TrophyOutlined, EnvironmentOutlined, ShopOutlined } from '@ant-design/icons';
import { Card, Col, Row, Typography, Tabs } from 'antd';
import { useEffect, useState, useContext } from 'react';

import { BreweryContext } from '../../context';
import { airtableURL } from '../../FetchURL';
import http from '../../http-common';
import Loading from '../Loading';

const { Title, Text } = Typography;

const WinnersPage = () => {
  const [brewery] = useContext(BreweryContext);
  const [currentTab, setCurrentTab] = useState(1);
  const [winnersData, setWinnersData] = useState(null);

  const items = [
    {
      key: '1',
      label: `All Awards`,
    },
    {
      key: '2',
      label: `My Awards`,
    },
  ];

  function myAwards() {
    if (!winnersData) return [];

    let results = [];
    for (let i = 0; i < winnersData.length; i++) {
      let winners = winnersData[i].winners.filter(function (item) {
        return item.brewery === brewery?.fields['Brewery Name'];
      });
      if (winners.length > 0) {
        winners.forEach(function (winner) {
          winner.category = winnersData[i].category;
        });
        results = results.concat(winners);
      }
    }
    return results;
  }

  useEffect(() => {
    http.get(airtableURL + '/winners').then((response) => {
      setWinnersData(response.data);
    });
  }, []);

  const medalColors = {
    Gold: '#e8b923',
    Silver: '#C0C0C0',
    Bronze: '#CD7F32',
    'Honorable Mention': '#EEEEEE',
  };

  return (
    <div className="custom-container">
      <div className="custom-label__header">
        <label>Awards</label>
      </div>

      {!winnersData ? (
        <div style={{ marginTop: 40 }}>
          <Loading />
        </div>
      ) : (
        <>
          <Tabs defaultActiveKey="1" items={items} onChange={(value) => setCurrentTab(parseInt(value))} />

          {currentTab === 1 && (
            <>
              {winnersData.map((category, index) => (
                <div key={index} style={{ marginBottom: 50 }}>
                  <Title level={4} style={{ marginBottom: 12 }}>
                    {category.category}
                  </Title>

                  <Row gutter={[20, 20]} align="middle">
                    {category.winners.map((winner, winnerIndex) => (
                      <Col flex="auto">
                        <Card
                          key={winnerIndex}
                          bordered={false}
                          style={{
                            borderRadius: '4px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            background: medalColors[winner.medal],
                          }}
                        >
                          <div style={{ padding: '12px' }}>
                            <Title level={5} style={{ marginBottom: '8px' }}>
                              {winner.medal}
                            </Title>
                            <Row align="middle" gutter={[8, 8]}>
                              <Col>
                                <TrophyOutlined />
                              </Col>
                              <Col flex="auto">
                                <Text strong>{winner.beer}</Text>
                              </Col>
                            </Row>
                            <Row align="middle" gutter={[8, 8]}>
                              <Col>
                                <EnvironmentOutlined />
                              </Col>
                              <Col flex="auto">
                                <Text>{winner.province}</Text>
                              </Col>
                            </Row>
                            <Row align="middle" gutter={[8, 8]}>
                              <Col>
                                <ShopOutlined />
                              </Col>
                              <Col flex="auto">
                                <Text>{winner.brewery}</Text>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </div>
              ))}
            </>
          )}

          {currentTab === 2 && (
            <>
              {myAwards().length === 0 ? (
                <Row align="middle">
                  <Typography.Title level={2}>No awards to show</Typography.Title>
                </Row>
              ) : (
                <>
                  {myAwards().map((data, index) => (
                    <div key={index} style={{ marginBottom: 50 }}>
                      <Title level={4} style={{ marginBottom: 12 }}>
                        {data.category}
                      </Title>

                      <Row gutter={[20, 20]} align="middle">
                        <Col flex="auto">
                          <Card
                            bordered={false}
                            style={{
                              borderRadius: '4px',
                              boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                              background: medalColors[data.medal],
                            }}
                          >
                            <div style={{ padding: '12px' }}>
                              <Title level={5} style={{ marginBottom: '8px' }}>
                                {data.medal}
                              </Title>
                              <Row align="middle" gutter={[8, 8]}>
                                <Col>
                                  <TrophyOutlined />
                                </Col>
                                <Col flex="auto">
                                  <Text strong>{data.beer}</Text>
                                </Col>
                              </Row>
                              <Row align="middle" gutter={[8, 8]}>
                                <Col>
                                  <EnvironmentOutlined />
                                </Col>
                                <Col flex="auto">
                                  <Text>{data.province}</Text>
                                </Col>
                              </Row>
                              <Row align="middle" gutter={[8, 8]}>
                                <Col>
                                  <ShopOutlined />
                                </Col>
                                <Col flex="auto">
                                  <Text>{data.brewery}</Text>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        </Col>
                      </Row>
                    </div>
                  ))}
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default WinnersPage;
