import { Button, Modal, Typography, message } from 'antd';
import { useState } from 'react';

import { airtableURL } from '../../FetchURL';
import httpCommon from '../../http-common';

export default function RemoveUserModal({
  userSelectedData,
  isRemoveUserModalOpen,
  handleRemoveUserModal,
  refetchUserData,
}) {
  const [messageApi, contextHolder] = message.useMessage();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleRemoveUser = async () => {
    try {
      setIsSubmitting(true);

      await httpCommon.delete(airtableURL + `/remove-user/${userSelectedData.id}`);
      messageApi.success('User removed!');

      refetchUserData();
      handleRemoveUserModal();

      httpCommon.post(airtableURL + `/logs`, {
        action: 'user removed',
        user: userSelectedData.email,
      });
    } catch (e) {
      console.log(e);
      messageApi.error('Error request. Please, check your connection and try again!');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Modal
        open={isRemoveUserModalOpen}
        onCancel={handleRemoveUserModal}
        title="Remove User"
        footer={[
          <Button disabled={isSubmitting} loading={isSubmitting} onClick={handleRemoveUser}>
            Remove
          </Button>,
        ]}
      >
        <Typography.Paragraph>
          Are you sure do you want to remove this user? ({userSelectedData.email})
        </Typography.Paragraph>
      </Modal>

      {contextHolder}
    </>
  );
}
