import FormInput from "../../FormInput";
import { Row, Col, Select } from "antd";
import { Provinces } from "../../../common/Variable";

import "../index.scss";

const Step2 = (props) => {
  return (
    <>
      <FormInput
        id="fullName"
        name="fullName"
        onChange={props.formik.handleChange}
        value={props.formik.values.fullName}
        placeHolder="Your Full Name"
      />
      {props.formik.errors.fullName && props.formik.touched.fullName && (
        <div className="custom__input-error">
          {props.formik.errors.fullName}
        </div>
      )}
      <FormInput
        id="companyName"
        name="companyName"
        onChange={props.formik.handleChange}
        value={props.formik.values.companyName}
        placeHolder="Company Name"
      />
      {props.formik.errors.companyName && props.formik.touched.companyName && (
        <div className="custom__input-error">
          {props.formik.errors.companyName}
        </div>
      )}
      <FormInput
        id="companyWebsite"
        name="companyWebsite"
        onChange={props.formik.handleChange}
        value={props.formik.values.companyWebsite}
        placeHolder="Company Website"
      />
      {props.formik.errors.companyWebsite &&
        props.formik.touched.companyWebsite && (
          <div className="custom__input-error">
            {props.formik.errors.companyWebsite}
          </div>
        )}
      <FormInput
        id="phoneNumber"
        name="phoneNumber"
        onChange={props.formik.handleChange}
        value={props.formik.values.phoneNumber}
        placeHolder="Phone Number"
      />
      {props.formik.errors.phoneNumber && props.formik.touched.phoneNumber && (
        <div className="custom__input-error">
          {props.formik.errors.phoneNumber}
        </div>
      )}
      <FormInput
        id="streetAddress"
        name="streetAddress"
        onChange={props.formik.handleChange}
        value={props.formik.values.streetAddress}
        placeHolder="Street Address"
      />
      {props.formik.errors.streetAddress &&
        props.formik.touched.streetAddress && (
          <div className="custom__input-error">
            {props.formik.errors.streetAddress}
          </div>
        )}
      <Row
        gutter={{
          xs: 8,
          sm: 10,
          md: 12,
          lg: 16,
        }}
      >
        <Col className="gutter-row" span={8}>
          <FormInput
            id="city"
            name="city"
            onChange={props.formik.handleChange}
            value={props.formik.values.city}
            placeHolder="City"
          />
          {props.formik.errors.city && props.formik.touched.city && (
            <div className="custom__input-error">
              {props.formik.errors.city}
            </div>
          )}
        </Col>
        <Col className="gutter-row" span={8}>
          <Select
            id="province"
            name="province"
            onChange={(value) => {
              props.formik.setFieldValue("province", value);
            }}
            value={props.formik.values.province}
            options={Provinces}
            className="w-100"
          />
          {props.formik.errors.province && props.formik.touched.province && (
            <div className="custom__input-error">
              {props.formik.errors.province}
            </div>
          )}
        </Col>
        <Col className="gutter-row" span={8}>
          <FormInput
            id="postalCode"
            name="postalCode"
            onChange={props.formik.handleChange}
            value={props.formik.values.postalCode}
            placeHolder="Postal Code"
          />
          {props.formik.errors.postalCode &&
            props.formik.touched.postalCode && (
              <div className="custom__input-error">
                {props.formik.errors.postalCode}
              </div>
            )}
        </Col>
      </Row>
    </>
  );
};

export default Step2;
