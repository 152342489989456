export const Provinces = [
  {
    value: 'Ontario',
    label: 'Ontario',
  },
];

export const Pour = [
  {
    value: 'Normal',
    label: 'Normal',
  },
  {
    value: 'DO NOT Rouse',
    label: 'DO NOT Rouse',
  },
  {
    value: 'Do Rouse',
    label: 'Do Rouse',
  },
];

export const StripePublickKey =
  'pk_test_51MQzTFCurLmAun6ZHEfkQp8I80wyG458nmN5BGLRimYF5jmxLLyY7T9Z7yLzfvi5Zq0J3uC4O85XF52exsjTdEuO00nvN8bkfK';

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
