import { Button, Row, Space, Table } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { DownloadOutlined } from '@ant-design/icons';
import { saveAs } from 'file-saver';
import html2pdf from 'html2pdf.js';
import JSZip from 'jszip';
import { renderToStaticMarkup } from 'react-dom/server';
import { airtableURL } from '../../FetchURL';
import { setAllDataForm } from '../../actions';
import { BreweryContext, EntriesContext } from '../../context';
import http from '../../http-common';
import Loading from '../Loading';
import PrintTastingNotesPage from '../PrintTastingNotesPage';
import './index.scss';

export default function TastingNotesListPage() {
  const navigate = useNavigate();
  const [brewery, setBrewery] = useContext(BreweryContext);
  const [entries, setEntries] = useContext(EntriesContext);
  const [isWinner, setIsWinner] = useState(false);
  const [tastingNotesData, setTastingNotesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loadingDownload, setloadingDownload] = useState(false);
  console.log('tastingNotesData', tastingNotesData);

  useEffect(() => {
    const fetchTastingNotesData = async () => {
      try {
        setLoading(true);
        const res = await http.get(airtableURL + `/tasting-notes/${brewery.fields['brewery-id']}`);
        if (res.status !== 200) return setLoading(false);
        console.log('res', res);

        const tastingNotesSorted = res.data.sort((a, b) =>
          a['Beer Name Plain Text'][0].localeCompare(b['Beer Name Plain Text'][0])
        );

        const obaTastingNotes = tastingNotesSorted.filter(
          (item) => item['Event ID'][0] === process.env.REACT_APP_EVENT_ID
        );

        setTastingNotesData(obaTastingNotes);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error('There was an error fetch auth', error);
        return;
      }
    };
    if (brewery?.fields) {
      fetchTastingNotesData();
    }
  }, [brewery]);

  // When tasting notes are defined, map through all of them and check if the brewery has any medals
  useEffect(() => {
    if (tastingNotesData.length > 0) {
      tastingNotesData.forEach((tastingNote) => {
        if (tastingNote['Medal']) {
          setIsWinner(true);
        }
      });
    }
  }, [tastingNotesData]);

  console.log('brewery', brewery);
  console.log('entries', entries);

  const columns = [
    {
      title: 'Beer Name',
      dataIndex: 'Beer Name Plain Text',
      key: 'Beer Name Plain Text',
      sorter: (a, b) => {
        console.log('sorter', a, b);
        return a['Beer Name Plain Text'][0].localeCompare(b['Beer Name Plain Text'][0]);
      },
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Judge ID',
      dataIndex: 'Judge ID',
      key: 'Judge ID',
    },
    {
      title: 'Round',
      dataIndex: 'Round',
      key: 'Round',
    },
    {
      title: 'Total Score',
      dataIndex: 'Total Score',
      key: 'Total Score',
    },
    {
      title: '',
      key: '',
      render: (_, record) => {
        // if (brewery.fields['Public Email'] === 'romaan@thehold.co') {
        return (
          <Space
            size="middle"
            onClick={() => {
              dispatch(
                setAllDataForm(JSON.parse(record['Notes']), record['Judge ID'], record['Round'], record['Beer ID'])
              );
              console.log(record);
              navigate(`/tasting-notes/${record._id}`, { replace: true });
            }}
          >
            <button className="checkout-button">View Full Note</button>
          </Space>
        );
        // }
      },
    },
  ];
  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleDownload = async () => {
    setloadingDownload(true);
    const rowsSelected = tastingNotesData.filter((value) => selectedRowKeys.includes(value._id));
    const recordLength = rowsSelected.length;

    const zip = new JSZip();
    let fileCount = 0;
    rowsSelected.forEach(async (record) => {
      const res = await http.get(airtableURL + `/entry/${record['Beer ID']}`);
      const html = renderToStaticMarkup(
        <PrintTastingNotesPage
          entryData={res.data.fields}
          judgingForm={
            setAllDataForm(JSON.parse(record['Notes']), record['Judge ID'], record['Round'], record['Beer ID']).payload
          }
        />
      );
      const file = await html2pdf()
        .set({
          image: { type: 'jpeg', quality: 1 },
          html2canvas: { dpi: 192, windowWidth: 1920, scale: 2.5 },
          pagebreak: { before: '.tastingnote__section' },
        })
        .from(html)
        .outputPdf();

      zip.file(`tasting_notes_${record._id}.pdf`, file, { binary: true, createFolders: true });
      fileCount++;
      console.log(fileCount, recordLength);
      if (fileCount >= recordLength) {
        zip.generateAsync({ type: 'blob' }).then(function (content) {
          // see FileSaver.js
          saveAs(content, 'tasting_notes.zip');
          setloadingDownload(false);
        });
      }
    });
  };
  return (
    <div className="custom-container">
      <div className="custom-label__header">
        <label>Tasting Notes</label>
      </div>
      <div className="events">
        {loading ? (
          <Loading />
        ) : (
          <>
            <Row justify="end" style={{ marginBottom: 15 }}>
              <Button
                loading={loadingDownload}
                className="checkout-button"
                icon=<DownloadOutlined />
                onClick={handleDownload}
                disabled={selectedRowKeys?.length <= 0}
              >
                Export
              </Button>
            </Row>
            <Table
              rowSelection={rowSelection}
              rowKey="_id"
              columns={columns}
              dataSource={tastingNotesData}
              pagination={{ pageSize: 25 }}
            />
          </>
        )}
      </div>
    </div>
  );
}
