import { useEffect, useState } from 'react';
import { Spin, Space, Layout } from 'antd';
import { useLocation } from 'react-router-dom';

import { UserContext, BreweryContext, EntriesContext, CategoriesContext } from './context';
import useWindowDimensions from './hooks/useWindowDimensions';
import CustomHeader from './components/CustomHeader';
import CustomFooter from './components/CustomFooter';
import CustomSider from './components/CustomSider';
import { authURL, airtableURL } from './FetchURL';
import http from './http-common';
import Routes from './Routes';
import 'antd/dist/reset.css';
import './App.scss';

const { Header, Footer, Sider, Content } = Layout;

function App() {
  const { width } = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  const [userSession, setUserSession] = useState({ _id: '', email: '' });
  const [userBrewery, setUserBrewery] = useState();
  const [collapsed, setCollapsed] = useState(width > 768 ? false : true);
  const [entries, setEntries] = useState({
    data: [],
    eventName: '',
  });
  const [categories, setCategories] = useState([]);
  const [current, setCurrent] = useState('1');
  const location = useLocation();

  useEffect(() => {
    const fetchUserAuth = async () => {
      try {
        setLoading(true);

        const resUser = await http.get(authURL + '/user');
        if (resUser.data === '') return setLoading(false);

        await http.get(airtableURL + `/get-brewery-id/${resUser.data.brewery[0]}`).then((res) => {
          console.log('resBrewery', res);
          setUserBrewery(res.data);
        });

        setUserSession((prevState) => ({
          ...prevState,
          _id: resUser.data._id,
          isAdmin: resUser.data.isAdmin,
          email: resUser.data.email,
          role: resUser.data.role,
          breweryId: resUser.data.brewery[0],
        }));

        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error('There was an error fetch auth', error);
        return;
      }
    };
    fetchUserAuth();
  }, []);

  useEffect(() => {
    if (!loading) {
      setUserSession((prevState) => ({
        ...prevState,
        fetched: true,
      }));
    }
  }, [loading]);

  useEffect(() => {
    const currentObj = {
      '/': '1',
      '/order-history': '2',
      '/settings': '3',
      '/users': '4',
      '/ticket-registration': '5',
      '/saved-entries': '6',
      '/winners': '7',
      '/tasting-notes': '8',
    };
    const path = '/' + location.pathname.split('/')[1];
    setCurrent(currentObj?.[path] || '1');
  }, [location]);

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  return (
    <UserContext.Provider value={[userSession, setUserSession]}>
      <BreweryContext.Provider value={[userBrewery, setUserBrewery]}>
        <EntriesContext.Provider value={[entries, setEntries]}>
          <CategoriesContext.Provider value={[categories, setCategories]}>
            {loading ? (
              <Space direction="vertical" className="w-100 h-100vh d-flex justify-center">
                <Spin tip="Loading" size="large">
                  <div className="content" />
                </Spin>
              </Space>
            ) : userSession._id !== '' ? (
              <Layout className="h-100">
                <Header>
                  <CustomHeader onSetCurrent={setCurrent} />
                </Header>

                <Layout>
                  <Sider
                    className="hidden-mobile"
                    collapsible
                    collapsed={collapsed}
                    collapsedWidth={0}
                    onCollapse={onCollapse}
                  >
                    <CustomSider current={current} onSetCurrent={setCurrent} setCollapsed={setCollapsed} />
                  </Sider>

                  <Content>
                    <Routes />
                  </Content>
                </Layout>
                <Footer>
                  <CustomFooter />
                </Footer>
              </Layout>
            ) : (
              <Routes />
            )}
          </CategoriesContext.Provider>
        </EntriesContext.Provider>
      </BreweryContext.Provider>
    </UserContext.Provider>
  );
}

export default App;
