export default function HeaderBlock({ title, children, outOfNumber }) {
  return (
    <div className="score-block">
      <div className="score-block__title">
        <h2 className="title">{title}</h2>
      </div>

      <div className="score-block__section">
        {children}
        <div className="score-block__section_text">
          <p className="text-gray" style={{ margin: 0 }}>
            Out of {outOfNumber}
          </p>
        </div>
      </div>
    </div>
  );
}
