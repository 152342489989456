import { QuestionCircleOutlined, UserOutlined, DownOutlined } from '@ant-design/icons';
import { Avatar, Button, Dropdown, Space, Tooltip } from 'antd';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import { BreweryContext, UserContext } from '../../context';
import { authURL } from '../../FetchURL';
import http from '../../http-common';
import HelpModal from '../HelpModal';
import './index.scss';

const CustomHeader = (props) => {
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const handleHelpModal = () => setIsHelpModalOpen(!isHelpModalOpen);

  const [userSession, setUserSession] = useContext(UserContext);
  const [brewery] = useContext(BreweryContext);

  const clickSettings = () => {
    props.onSetCurrent('3');
  };

  const logoutClick = async () => {
    await http.get(authURL + '/logout');
    setUserSession({ _id: '', email: '' });
    sessionStorage.removeItem('nextPath');
    window.location.href = '/login';
  };

  const items = [
    {
      label: (
        <Link to="/settings" onClick={clickSettings}>
          Profile Settings
        </Link>
      ),
      key: '0',
    },
    {
      type: 'divider',
    },
    {
      label: (
        <Link to="/logout" style={{ color: 'red' }} onClick={logoutClick}>
          Log out
        </Link>
      ),
      key: '2',
    },
  ];

  const itemsMobile = [
    {
      label: (
        <Link to="/events" onClick={() => props.onSetCurrent('1')}>
          Events
        </Link>
      ),
      key: '0',
    },
    {
      label: (
        <Link to="/order-history" onClick={() => props.onSetCurrent('2')}>
          Orders
        </Link>
      ),
      key: '1',
    },
    {
      label: (
        <Link to="/saved-entries" onClick={() => props.onSetCurrent('6')}>
          Saved Entries
        </Link>
      ),
      key: '2',
    },
    {
      label: (
        <Link to="/tasting-notes" onClick={() => props.onSetCurrent('5')}>
          Tasting Notes
        </Link>
      ),
      key: '8',
    },
    {
      label: (
        <Link to="/winners" onClick={() => props.onSetCurrent('5')}>
          Winners
        </Link>
      ),
      key: '7',
    },
    {
      label: (
        <Link to="/users" onClick={() => props.onSetCurrent('4')}>
          Users
        </Link>
      ),
      key: '3',
    },
    {
      label: (
        <Link to="/settings" onClick={clickSettings}>
          Profile Settings
        </Link>
      ),
      key: '4',
    },
    {
      type: 'divider',
    },
    {
      label: (
        <Link to="/logout" style={{ color: 'red' }} onClick={logoutClick}>
          Log out
        </Link>
      ),
      key: '5',
    },
  ];

  return (
    <>
      <div className="h-100">
        {brewery ? (
          <div className="d-flex justify-between align-center h-100 header">
            <Link to="/">
              <img
                className="cbac-logo"
                src="https://ontariobrewingawards.com/wp-content/uploads/2020/09/cropped-OBA_logo_FINAL-1-1-175x101.png"
                alt="logo-small"
              />
            </Link>

            <div className="hidden-mobile">
              <div className="d-flex align-center">
                <Tooltip placement="bottom" title={'Need some help? Click here!'}>
                  <QuestionCircleOutlined style={{ marginRight: 24, fontSize: 24 }} onClick={handleHelpModal} />
                </Tooltip>

                <div className="d-flex flex-column" style={{ marginRight: 8 }}>
                  <label className="header__brewery-name">{brewery && brewery.fields['Brewery Name']}</label>
                  <label className="header__user-email">{userSession.email}</label>
                </div>

                <Dropdown
                  menu={{
                    items,
                  }}
                  trigger={['click']}
                >
                  {brewery.fields.Logo ? (
                    <img src={brewery.fields.Logo[0].url} className="header__logo" alt="logo" />
                  ) : (
                    <Avatar className="cursor-pointer" icon={<UserOutlined />} />
                  )}
                </Dropdown>
              </div>
            </div>

            <div className="hidden-desktop">
              <div className="d-flex align-center">
                <QuestionCircleOutlined style={{ marginRight: 20, fontSize: 24 }} onClick={handleHelpModal} />

                <Dropdown
                  menu={{
                    items: itemsMobile,
                  }}
                  trigger={['click']}
                >
                  <Button>
                    <Space>
                      Menu
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>

      <HelpModal isHelpModalOpen={isHelpModalOpen} handleHelpModal={handleHelpModal} />
    </>
  );
};

export default CustomHeader;
