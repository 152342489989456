import { UploadOutlined } from '@ant-design/icons';
import { message, Upload, Button } from 'antd';
import { useContext, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { BreweryContext, UserContext } from '../../context';
import ResetPasswordModal from '../ResetPasswordModal';
import { airtableURL, authURL } from '../../FetchURL';
import cloudinaryUpload from '../../services/uploads';
import CustomButton from '../CustomButton';
import FormInput from '../FormInput';
import http from '../../http-common';
import Loading from '../Loading';
import './index.scss';

const Settings = () => {
  const [brewery, setBrewery] = useContext(BreweryContext);
  const [user] = useContext(UserContext);
  const [pictureLoading, setPictureLoading] = useState(false);
  const [pictureURL, setPictureURL] = useState('');
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleUpload = async (e) => {
    const uploadData = new FormData();
    uploadData.append('file', e, 'file');
    setPictureLoading(true);
    const res = await cloudinaryUpload(uploadData);
    setPictureLoading(false);
    // props.formik.values.companyLogo = res.secure_url;
    setPictureURL(res.secure_url);
  };

  const formik = useFormik({
    initialValues: {
      settingsName: brewery.fields['Brewery Name'],
      settingsContact: brewery.fields['Contact Full Name'],
      settingsEmail: brewery.fields['Public Email'],
      settingsLogo: brewery.fields.Logo ? brewery.fields.Logo[0].url : '',
    },
    onSubmit: async (values) => {
      setLoading(true);
      if (pictureURL !== '') values.settingsLogo = pictureURL;
      formik.setSubmitting(false);
      try {
        await http.post(airtableURL + '/update-brewery', {
          values,
          id: brewery.id,
        });
        await http.patch(authURL + `/${user._id}/email`, {
          email: values.settingsEmail,
        });
        await http.get(airtableURL + `/get-brewery-id/${brewery.id}`).then((res) => {
          setBrewery(res.data);
        });
        setLoading(false);
        message.success('Update Success');
      } catch (error) {
        setLoading(false);
        message.error(error.response.data ? error.response.data.error : 'Update Entry Error! Please try again');
      }
    },
    validationSchema: Yup.object().shape({
      settingsName: Yup.string().required('Required'),
      settingsContact: Yup.string().required('Required'),
      settingsEmail: Yup.string().email('Invalid email').required('Required'),
    }),
  });

  return (
    <div className="custom-container">
      <div className="custom-label__header">
        <label>Settings</label>
      </div>
      <div className="settings">
        <div className="settings__row d-flex">
          <div className="settings__label">
            <label className="settings__label-letter">Logo</label>
          </div>
          <div className="settings__logo">
            {pictureLoading ? (
              <Loading />
            ) : (
              <Upload
                name="logo"
                action={(e) => handleUpload(e)}
                listType="picture"
                maxCount={1}
                disabled={user?.role !== 'Admin'}
              >
                {pictureURL !== '' ? (
                  <img src={pictureURL} className="settings__logo" alt="logo" />
                ) : brewery.fields.Logo ? (
                  <img src={brewery.fields.Logo[0].url} className="settings__logo" alt="logo" />
                ) : (
                  <Button icon={<UploadOutlined />}>Upload Company Logo</Button>
                )}
              </Upload>
            )}
          </div>
        </div>
        <div className=" d-flex">
          <div className="settings__label">
            <label className="settings__label-letter">Brewery Name</label>
          </div>
          <div className="settings__input">
            <FormInput
              id="settingsName"
              name="settingsName"
              onChange={formik.handleChange}
              value={formik.values.settingsName}
              placeHolder="Name"
              disabled={user?.role !== 'Admin'}
            />
            {formik.errors.settingsName && formik.touched.settingsName && (
              <div className="custom__input-error">{formik.errors.settingsName}</div>
            )}
          </div>
        </div>
        <div className=" d-flex">
          <div className="settings__label">
            <label className="settings__label-letter">Contact Name</label>
          </div>
          <div className="settings__input">
            <FormInput
              id="settingsContact"
              name="settingsContact"
              onChange={formik.handleChange}
              value={formik.values.settingsContact}
              placeHolder="Contact"
              disabled={user?.role !== 'Admin'}
            />
            {formik.errors.settingsContact && formik.touched.settingsContact && (
              <div className="custom__input-error">{formik.errors.settingsContact}</div>
            )}
          </div>
        </div>
        <div className=" d-flex">
          <div className="settings__label">
            <label className="settings__label-letter">Email</label>
          </div>
          <div className="settings__input">
            <FormInput
              id="settingsEmail"
              name="settingsEmail"
              onChange={formik.handleChange}
              value={formik.values.settingsEmail}
              placeHolder="Email"
              disabled={user?.role !== 'Admin'}
            />
            {formik.errors.settingsEmail && formik.touched.settingsEmail && (
              <div className="custom__input-error">{formik.errors.settingsEmail}</div>
            )}
          </div>
        </div>
        <div className="d-flex">
          <div className="settings__label">
            <label className="settings__label-letter">Password</label>
          </div>
          <div className="settings__input">
            <input
              disabled={user?.role !== 'Admin'}
              className="settings__input__button"
              type="button"
              value="Reset Password"
              onClick={showModal}
            />
          </div>
        </div>
        <div style={{ marginTop: '20px', width: '100px' }}>
          <CustomButton
            primary="primary"
            label="Save"
            loading={loading}
            disabled={user?.role !== 'Admin'}
            onClick={() => {
              formik.submitForm();
            }}
          />
        </div>
      </div>
      <ResetPasswordModal isModalOpen={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} />
    </div>
  );
};

export default Settings;
