import { Checkbox, Col, Row, Slider, Space } from 'antd';

import { LIMIT_SCORE } from '../../../consts/LIMIT_SCORE';
import BlockSlider from '../../JudgingFormComponents/BlockSlider';
import ScoringGuide from './ScoringGuide';

export default function Appearance({ appearanceStore }) {
  return (
    <>
      <div className="tastingnote__section form__section w-full">
        <Row className="form__section_title">
          <Col xs={12}>
            <h1>Appearance</h1>
          </Col>

          <Col xs={12} style={{ marginBottom: 2 }}>
            <Space align="end" direction="vertical" size={1} className="w-full">
              <Row>
                <input disabled={true} className="score" value={appearanceStore.score.value} type="text" />
                <Space align="end">
                  <p className="text-gray" style={{ marginLeft: 4, textAlign: 'center' }}>
                    Out of {LIMIT_SCORE.appearance}
                  </p>
                </Space>
              </Row>
            </Space>
          </Col>
        </Row>

        <Row style={{ marginTop: 15 }}>
          <BlockSlider title="Appearance score" score={appearanceStore.score.value}>
            <Slider
              disabled={true}
              value={appearanceStore.score.value}
              defaultValue={0}
              style={{ marginTop: 18 }}
              min={0}
              max={LIMIT_SCORE.appearance}
            />
          </BlockSlider>
        </Row>

        <ScoringGuide />

        <Row className="section-margin-y">
          <BlockSlider title="Clarity" score={appearanceStore.clarity.value}>
            <Slider
              disabled={true}
              value={appearanceStore.clarity.value}
              defaultValue={0}
              style={{ marginTop: 18 }}
              min={0}
              max={5}
            />
          </BlockSlider>

          <BlockSlider title="Head" score={appearanceStore.head.value}>
            <Slider
              disabled={true}
              value={appearanceStore.head.value}
              defaultValue={0}
              style={{ marginTop: 18 }}
              min={0}
              max={5}
            />
          </BlockSlider>

          <BlockSlider title="Retention" score={appearanceStore.retention.value}>
            <Slider
              disabled={true}
              value={appearanceStore.retention.value}
              defaultValue={0}
              style={{ marginTop: 18 }}
              min={0}
              max={5}
            />
          </BlockSlider>
        </Row>

        <Row className="section-margin-y">
          <Col xs={24} lg={8}>
            <Space style={{ marginBottom: 16 }}>
              <h3 className="title">Head Texture</h3>
            </Space>

            <p disabled={true} className="w-full comments" style={{ marginBottom: 8 }}>
              {appearanceStore.headTexture.value}
            </p>
          </Col>
        </Row>

        <Row className="section-margin-y">
          <Col xs={24} md={12} lg={8} style={{ marginBottom: 10 }} className="flex-column">
            <Space style={{ marginBottom: 5 }}>
              <h3 className="title">Beer Colour</h3>
            </Space>

            <Row>
              <Col span={24}>
                <Checkbox disabled={true} checked={appearanceStore.beerColor.straw} value="Straw">
                  Straw
                </Checkbox>
                <Checkbox disabled={true} checked={appearanceStore.beerColor.yellow} value="Yellow">
                  Yellow
                </Checkbox>
                <Checkbox disabled={true} value="Gold" checked={appearanceStore.beerColor.gold}>
                  Gold
                </Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox disabled={true} value="Amber" checked={appearanceStore.beerColor.amber}>
                  Amber
                </Checkbox>
                <Checkbox value="Copper" disabled={true} checked={appearanceStore.beerColor.copper}>
                  Copper
                </Checkbox>
                <Checkbox value="Brown" disabled={true} checked={appearanceStore.beerColor.brown}>
                  Brown
                </Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox value="Black" disabled={true} checked={appearanceStore.beerColor.black}>
                  Black
                </Checkbox>
              </Col>
            </Row>
          </Col>

          <Col xs={24} md={12} lg={8} style={{ marginBottom: 10 }} className="flex-column">
            <Space style={{ marginBottom: 5 }}>
              <h3 className="title">Head Color</h3>
            </Space>

            <Row>
              <Col span={24}>
                <Checkbox value="White" disabled={true} checked={appearanceStore.headColor.white}>
                  White
                </Checkbox>
                <Checkbox disabled={true} value="Ivory" checked={appearanceStore.headColor.ivory}>
                  Ivory
                </Checkbox>
                <Checkbox disabled={true} value="Cream" checked={appearanceStore.headColor.cream}>
                  Cream
                </Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox disabled={true} value="Beige" checked={appearanceStore.headColor.beige}>
                  Beige
                </Checkbox>
                <Checkbox disabled={true} value="Tan" checked={appearanceStore.headColor.tan}>
                  Tan
                </Checkbox>
                <Checkbox disabled={true} value="Brown" checked={appearanceStore.headColor.brown}>
                  Brown
                </Checkbox>
              </Col>
            </Row>
          </Col>

          <Col xs={24} md={12} lg={8} style={{ marginBottom: 10 }} className="flex-column">
            <Space style={{ marginBottom: 5 }}>
              <h3 className="title">Other</h3>
            </Space>

            <Row>
              <Col span={24}>
                <Checkbox disabled={true} value="Flat" checked={appearanceStore.otherColor.flat}>
                  Flat
                </Checkbox>
                <Checkbox disabled={true} value="Lace" checked={appearanceStore.otherColor.lace}>
                  Lace
                </Checkbox>
                <Checkbox disabled={true} value="Legs" checked={appearanceStore.otherColor.legs}>
                  Legs
                </Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox disabled={true} value="Opaque" checked={appearanceStore.otherColor.opaque}>
                  Opaque
                </Checkbox>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}
