import { LeftOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Form, Space, Row, Col, Typography, Button } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Title from 'antd/es/typography/Title';
import { useSelector } from 'react-redux';
import ReactToPrint from 'react-to-print';

import RequestError from '../JudgingFormComponents/RequestError';
import HeaderBlock from '../JudgingFormComponents/HeaderBlock';
import { LIMIT_SCORE } from '../../consts/LIMIT_SCORE';
import Appearance from '../JudgingForm/Appearance';
import Mouthfeel from '../JudgingForm/Mouthfeel';
import Overall from '../JudgingForm/Overall';
import { airtableURL } from '../../FetchURL';
import Flavor from '../JudgingForm/Flavor';
import Aroma from '../JudgingForm/Aroma';
import http from '../../http-common';
import Loading from '../Loading';
import './index.scss';
import platinumSponsorLogo from '../../assets/img/sponsors/fasttrack.png';
import goldSponsor1Logo from '../../assets/img/sponsors/craftcoast.png';
import goldSponsor2Logo from '../../assets/img/sponsors/DME.png';
import goldSponsor3Logo from '../../assets/img/sponsors/kegshare.png';
import goldSponsor4Logo from '../../assets/img/sponsors/lallemand.png';
import { EVENT_NAME_ABRV } from '../../consts/EVENT_CONSTS';

const { Paragraph } = Typography;

export default function TastingNoteViewPage() {
  const judgingForm = useSelector((state) => state.judgingForm);
  const [status, setStatus] = useState('loading'); // loading, error, success
  const [entryData, setEntryData] = useState([]);
  const sectionToPrint = useRef();
  const navigate = useNavigate();

  console.log('entryData', entryData);

  const getEntry = async () => {
    try {
      setStatus('loading');

      const res = await http.get(airtableURL + `/entry/${judgingForm.beerID}`);
      setEntryData(res.data.fields);

      setStatus('success');
    } catch {
      setStatus('error');
    }
  };

  useEffect(() => {
    // if it is undefined, it means there is no data store to be able to show this form

    if (judgingForm.aroma === undefined) {
      navigate('/tasting-notes');
    } else {
      getEntry();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (status === 'loading') return <Loading />;

  if (status === 'error') return <RequestError refetch={getEntry} />;

  return (
    <div className="form-container" ref={sectionToPrint}>
      <Form className="form">
        <Title level={2} className="form__title">
          <LeftOutlined
            style={{ marginRight: 13 }}
            className="cursor-pointer"
            onClick={() => navigate('/tasting-notes')}
          />
          {entryData?.['beer name']}
        </Title>

        <div className="form__button-to-print">
          <ReactToPrint
            trigger={() => (
              <Button size="large" icon={<FilePdfOutlined />}>
                Print
              </Button>
            )}
            content={() => sectionToPrint.current}
            documentTitle={`${EVENT_NAME_ABRV} Judging Form - JudgeID ${judgingForm.judgeID} - Round ${judgingForm.round}`}
          />
        </div>

        <div className="form__logo">
          <img
            alt="Logo"
            src="https://ontariobrewingawards.com/wp-content/uploads/2020/09/cropped-OBA_logo_FINAL-1-1.png"
            style={{ maxWidth: '160px' }}
          />
        </div>

        <div className="form__header">
          <Row className="form__header__main">
            <Col xs={24} md={5} lg={5} style={{ paddingRight: 4 }}>
              <Space align="start">
                <Typography>
                  <Paragraph strong style={{ fontSize: '24px', marginBottom: 10 }}>
                    {entryData?.['beer-id']}
                  </Paragraph>
                </Typography>
              </Space>
            </Col>
            <Col xs={24} md={4} lg={4}>
              <Space>
                <Typography>
                  <Paragraph strong className="text-gray">
                    Category
                  </Paragraph>
                  <Paragraph className="text-gray">
                    {entryData?.['category code']} - {entryData?.['category name']}
                  </Paragraph>
                </Typography>
              </Space>
            </Col>
            <Col xs={24} md={1} lg={1}></Col>
            <Col xs={24} md={14} lg={14}>
              <Space>
                <Typography>
                  <Paragraph strong className="text-gray">
                    Description:
                  </Paragraph>

                  <Paragraph className="text-gray">
                    {entryData?.['Description'] ? 'No description.' : entryData?.['Description']}
                  </Paragraph>
                </Typography>
              </Space>
            </Col>
          </Row>

          <Row style={{ marginTop: 10 }}>
            <Col>
              <Space className="margin-x" style={{ marginLeft: 0 }}>
                <Typography>
                  <Paragraph strong>Scoring Guide:</Paragraph>
                </Typography>
              </Space>
              <Space className="margin-x">
                <Typography>
                  <Paragraph>0 - N/A</Paragraph>
                </Typography>
              </Space>
              <Space className="margin-x">
                <Typography>
                  <Paragraph>1 - Low</Paragraph>
                </Typography>
              </Space>
              <Space className="margin-x">
                <Typography>
                  <Paragraph>2 - Medium</Paragraph>
                </Typography>
              </Space>
              <Space className="margin-x">
                <Typography>
                  <Paragraph>3 - High</Paragraph>
                </Typography>
              </Space>
            </Col>
          </Row>

          <Row className="form__header__scores">
            <HeaderBlock title="Aroma" outOfNumber={LIMIT_SCORE.aroma}>
              <input className="score" value={judgingForm.aroma.score.value} type="text" disabled />
            </HeaderBlock>

            <HeaderBlock title="Appearance" outOfNumber={LIMIT_SCORE.appearance}>
              <input className="score" type="text" disabled value={judgingForm.appearance.score.value} />
            </HeaderBlock>

            <HeaderBlock title="Flavor" outOfNumber={LIMIT_SCORE.flavor}>
              <input className="score" type="text" disabled value={judgingForm.flavor.score.value} />
            </HeaderBlock>

            <HeaderBlock title="Mouthfeel" outOfNumber={LIMIT_SCORE.mouthfeel}>
              <input className="score" type="text" value={judgingForm.mouthfeel.score.value} disabled />
            </HeaderBlock>

            <HeaderBlock title="Overall" outOfNumber={LIMIT_SCORE.overall} max={LIMIT_SCORE.overall}>
              <input className="score" type="text" disabled value={judgingForm.overall.score.value} />
            </HeaderBlock>

            <HeaderBlock title="Total" outOfNumber={LIMIT_SCORE.total}>
              <input
                className="score"
                type="text"
                disabled
                value={
                  Number(judgingForm.aroma.score.value) +
                  Number(judgingForm.appearance.score.value) +
                  Number(judgingForm.flavor.score.value) +
                  Number(judgingForm.mouthfeel.score.value) +
                  Number(judgingForm.overall.score.value)
                }
              />
            </HeaderBlock>
          </Row>
        </div>

        <div className="form__judge-id">
          <p className="form__judge-id_text">
            Judge ID: <span>{judgingForm.judgeID}</span>
          </p>
        </div>

        <div className="form__sponsors tastingnote__section">
          <div>
            <h4>Sponsored By:</h4>
          </div>

          <div>
            <a href="https://ftpackaging.ca/">
              <img alt="Sponsor" src={platinumSponsorLogo} />
            </a>
          </div>

          <div>
            <a href="https://craftcoastcanning.com/">
              <img alt="Sponsor" src={goldSponsor1Logo} />
            </a>
          </div>

          <div>
            <a href="https://dme.ca/">
              <img alt="Sponsor" src={goldSponsor2Logo} />
            </a>
          </div>

          <div>
            <a href="https://kegshare.ca/">
              <img alt="Sponsor" src={goldSponsor3Logo} />
            </a>
          </div>

          <div>
            <a href="https://www.lallemand.com/">
              <img alt="Sponsor" src={goldSponsor4Logo} />
            </a>
          </div>
        </div>

        <div>
          <Aroma disabled />
          <Appearance disabled />
          <Flavor disabled />
          <Mouthfeel disabled />
          <Overall disabled />
        </div>
      </Form>
    </div>
  );
}
