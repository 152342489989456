import { Alert, Space } from 'antd';

const CustomAlert = (props) => {
  return (
    <Space
      direction="vertical"
      style={{
        width: '300px',
        position: 'absolute',
        top: '20px',
        right: '10px',
      }}
    >
      <Alert
        message={props.message}
        type={props.type}
        closable
        onClose={() => {
          props.onClose('');
        }}
      />
    </Space>
  );
};

export default CustomAlert;
