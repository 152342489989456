import { Button, Form, Modal, Input, Typography, message, Select } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { endpointUrl } from 'airtable';

import { airtableURL, authURL, emailURL } from '../../FetchURL';
import { BreweryContext, UserContext } from '../../context';
import httpCommon from '../../http-common';

const ROL = ['User'];

export default function RequestInvitationModal({
  isRequestInvitationModalOpen,
  handleRequestInvitationModal,
  refetchUserData,
  requestInvitationEmail,
}) {
  const [user] = useContext(UserContext);
  const [brewery] = useContext(BreweryContext);

  const [messageApi, contextHolder] = message.useMessage();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = Form.useForm();

  const handleFinish = async (values) => {
    try {
      setIsSubmitting(true);

      const response = await httpCommon.post(authURL + '/create-user-from-brewery', {
        email: values.email.toLowerCase(),
        role: 'User',
        password: values.password,
        breweryId: user.breweryId,
      });

      await httpCommon.post(emailURL + '/new-user', {
        email: values.email.toLowerCase(),
        breweryName: brewery.fields['Brewery Name'],
        password: response.data.password,
      });

      messageApi.success('User added!');
      refetchUserData();

      form.resetFields();
      handleRequestInvitationModal();

      httpCommon.post(airtableURL + `/logs`, {
        action: 'user added',
        user: values.email,
      });
    } catch (e) {
      console.log(e);
      messageApi.error('Error request. Please, check your connection and try again!');
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    form.setFieldValue('email', requestInvitationEmail);
    sessionStorage.removeItem('nextPath');
  }, [form, requestInvitationEmail]);

  return (
    <>
      <Modal
        open={isRequestInvitationModalOpen}
        onCancel={handleRequestInvitationModal}
        title="Request Invitation"
        footer={[
          <Button disabled={isSubmitting} loading={isSubmitting} onClick={() => form.submit()}>
            Add
          </Button>,
        ]}
      >
        <Form form={form} onFinish={handleFinish}>
          <Form.Item
            name="email"
            style={{ padding: 0, margin: 0, height: 0 }}
            rules={[{ required: true }, { type: 'email', message: 'Enter a valid email' }]}
          >
            <Input style={{ padding: 0, margin: 0 }} type="hidden" disabled={isSubmitting} placeholder="Email" />
          </Form.Item>
        </Form>

        <Typography.Paragraph>Would you like to add {requestInvitationEmail} to users?</Typography.Paragraph>
      </Modal>

      {contextHolder}
    </>
  );
}
