import { Button, Result, Space } from "antd";

export default function RequestError({ refetch }) {
  return (
    <Result
      title="Request Error."
      status="error"
      extra={
        <Space align="center">
          <Button onClick={() => refetch()}>Try again!</Button>
        </Space>
      }
    />
  );
}
