import { SET_ALL_DATA_FORM } from "./types";

// Set all judging form data in store
export const setAllDataForm = (data, judgeID, round, beerID) => {
  data.currentSection = "aroma";
  data.judgeID = judgeID;
  data.round = round;
  data.beerID = beerID;

  return {
    type: SET_ALL_DATA_FORM,
    payload: data,
  };
};
