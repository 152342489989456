import { Col, Row, Space, Typography } from 'antd';

import obaLogo from '../../assets/img/OBA-Logo.png';
import goldSponsor2Logo from '../../assets/img/sponsors/DME.png';
import goldSponsor1Logo from '../../assets/img/sponsors/craftcoast.png';
import platinumSponsorLogo from '../../assets/img/sponsors/fasttrack.png';
import goldSponsor3Logo from '../../assets/img/sponsors/kegshare.png';
import goldSponsor4Logo from '../../assets/img/sponsors/lallemand.png';
import { LIMIT_SCORE } from '../../consts/LIMIT_SCORE';
import HeaderBlock from '../JudgingFormComponents/HeaderBlock';
import Appearance from './JudgingForm/Appearance';
import Aroma from './JudgingForm/Aroma';
import Flavor from './JudgingForm/Flavor';
import Mouthfeel from './JudgingForm/Mouthfeel';
import Overall from './JudgingForm/Overall';
// import './index.scss';

const { Paragraph } = Typography;
const style = `
	.print-body {
		 background-color: #f5f5f5;
     width:794px !important;
	}
 .form-container {
	 display: block;
	 padding: 30px;
	 overflow: hidden;
}
 .form {
	 display: flex;
	 justify-content: flex-start;
	 align-items: flex-start;
	 flex-direction: column;
	 row-gap: 10px;
}


	 .form__logo {
		 display: block;
	}
 .form__header {
	 display: flex;
	 flex-direction: column;
	 align-items: flex-start;
	 padding: 17px;
	 width: 100%;
	 background: #fff;
	 border-radius: 8px;
}
 .form__header__main {
	 width: 100%;
	 background: rgba(0, 0, 0, 0.04);
	 padding: 16px;
}
 .form__header__scores {
	 width: 100%;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 flex-direction: row;
	 flex-wrap: wrap;
}
 .form__header__scores div {
	 flex: 1;
	 min-width: 130px;
}

 .form__judge-id {
	 width: 100%;
	 margin-left: 50px;
	 margin-top: 14px;
}
 .form__judge-id_text {
	 font-size: 20px;
	 font-weight: bold;
}
 .form__judge-id_text span {
	 font-weight: initial;
}
 .form__section {
	 padding: 5px 17px;
	 margin-bottom: 20px;
	 background: #fff;
	 border-radius: 8px;
}
 .form__section_title {
	 margin-bottom: 18px;
}
 .form__section_title h1 {
	 font-size: 30px;
	 color: #262626;
	 text-decoration-line: underline;
}
 .form__section_subtitle {
	 margin-bottom: 20px;
	 margin-top: 15px;
}
 .form__section_subtitle h1 {
	 font-size: 24px;
	 color: #262626;
}
 .form__section__slider_item {
	 margin-left: 7px;
	 margin-top: 8px;
	 background: #f0f0f0;
	 text-align: center;
	 border: 1px solid black;
	 width: 33px;
	 height: 32px;
	 border: 1px solid #d9d9d9;
	 border-radius: 2px;
	 display: flex;
	 align-items: center;
	 justify-content: center;
}
 .form__section__slider_item div p {
	 margin-bottom: 0;
	 font-size: 1.5em;
}
 .form__footer {
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 width: 100%;
}
 .form__footer__buttons {
	 display: flex;
	 justify-content: center;
	 align-items: center;
	 flex-direction: row;
	 font-size: 1.2em;
	 width: 100%;
	 max-width: 680px;
	 flex-wrap: wrap;
	 box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.02), 0px 6px 16px rgba(0, 0, 0, 0.04), 0px 3px 6px -4px rgba(0, 0, 0, 0.06);
}
 .form__footer__buttons_item, .form__footer__buttons_item--active {
	 background: #fff;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 flex-direction: column;
	 flex: 1;
	 height: 88px;
	 padding: 10px;
	 text-transform: uppercase;
	 transition: 0.2s all;
	 color: gray;
}
 .form__footer__buttons_item span, .form__footer__buttons_item--active span {
	 font-size: 25px;
}
 .form__footer__buttons_item:hover, .form__footer__buttons_item--active:hover {
	 cursor: pointer;
	 user-select: none;
	 background-color: #ebebeb;
}
 .form__footer__buttons_item--active {
	 color: #ad6800;
	 border-top: 3px solid #ad6800;
	 background-color: #ebebeb;
}
 .form__footer__buttons_item--active:hover {
	 cursor: initial;
}
 .form__sponsors {
	 display: none;
	 align-items: center;
	 justify-content: flex-start;
	 gap: 8px;
	 flex-wrap: wrap;
}
 .form__sponsors div h4 {
	 font-size: 20px;
}
 .form__sponsors div a img {
	 max-width: 90px;
}
	 .form__sponsors {
		 display: flex;
	}
 .score-block {
	 width: 100%;
}
 .score-block__title h2 {
	 margin-bottom: 0;
}
 .score-block__section {
	 display: flex;
	 flex-direction: row;
}
 .score-block__section_text {
	 display: flex;
	 align-items: flex-end;
	 margin-left: 6px;
}
 .score {
	 font-size: 24px;
	 margin-top: 8px;
	 background: #f0f0f0;
	 text-align: center;
	 border: 1px solid black;
	 width: 50px;
	 height: 48px;
	 border: 1px solid #d9d9d9;
	 border-radius: 2px;
   padding-top:12px;
}
 .score p {
	 font-size: 1.2em;
}
 .section-margin-y {
	 margin-top: 24px;
	 margin-bottom: 24px;
}
}
 .margin-x {
	 margin-left: 6px;
	 margin-right: 6px;
}
 .margin-y {
	 margin-top: 6px;
	 margin-bottom: 6px;
}
 .w-full {
	 width: 100%;
}
.comments{
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  padding: 7px 15px;
}

 `;
export default function PrintTastingNotesPage({ entryData, judgingForm }) {
  return (
    <>
      <style>{style}</style>
      <div className="print-body">
        <div className="form-container" style={{ marginTop: 0, paddingTop: 15 }}>
          <div className="form">
            <div>
              <img alt="Logo" src={obaLogo} style={{ maxWidth: '160px' }} />
            </div>

            <div className="form__header">
              <Row className="form__header__main">
                <Col xs={24} md={5} lg={5} style={{ paddingRight: 4 }}>
                  <Space align="start">
                    <Typography>
                      <Paragraph strong style={{ fontSize: '24px', marginBottom: 10 }}>
                        {entryData?.['beer-id']}
                      </Paragraph>
                    </Typography>
                  </Space>
                </Col>
                <Col xs={24} md={4} lg={4}>
                  <Space>
                    <Typography>
                      <Paragraph strong className="text-gray">
                        Category
                      </Paragraph>
                      <Paragraph className="text-gray">
                        {entryData?.['category code']} - {entryData?.['category name']}
                      </Paragraph>
                    </Typography>
                  </Space>
                </Col>
                <Col xs={24} md={1} lg={1}></Col>
                <Col xs={24} md={14} lg={14}>
                  <Space>
                    <Typography>
                      <Paragraph strong className="text-gray">
                        Description:
                      </Paragraph>

                      <Paragraph className="text-gray">
                        {entryData?.['Description'] ? 'No description.' : entryData?.['Description']}
                      </Paragraph>
                    </Typography>
                  </Space>
                </Col>
              </Row>

              <Row style={{ marginTop: 10 }}>
                <Col>
                  <Space className="margin-x" style={{ marginLeft: 0 }}>
                    <Typography>
                      <Paragraph strong>Scoring Guide:</Paragraph>
                    </Typography>
                  </Space>
                  <Space className="margin-x">
                    <Typography>
                      <Paragraph>0 - N/A</Paragraph>
                    </Typography>
                  </Space>
                  <Space className="margin-x">
                    <Typography>
                      <Paragraph>1 - Low</Paragraph>
                    </Typography>
                  </Space>
                  <Space className="margin-x">
                    <Typography>
                      <Paragraph>2 - Medium</Paragraph>
                    </Typography>
                  </Space>
                  <Space className="margin-x">
                    <Typography>
                      <Paragraph>3 - High</Paragraph>
                    </Typography>
                  </Space>
                </Col>
              </Row>

              <Row className="form__header__scores">
                <HeaderBlock title="Aroma" outOfNumber={LIMIT_SCORE.aroma}>
                  <input className="score" value={judgingForm.aroma.score.value} type="text" disabled />
                </HeaderBlock>

                <HeaderBlock title="Appearance" outOfNumber={LIMIT_SCORE.appearance}>
                  <input className="score" type="text" disabled value={judgingForm.appearance.score.value} />
                </HeaderBlock>

                <HeaderBlock title="Flavor" outOfNumber={LIMIT_SCORE.flavor}>
                  <input className="score" type="text" disabled value={judgingForm.flavor.score.value} />
                </HeaderBlock>

                <HeaderBlock title="Mouthfeel" outOfNumber={LIMIT_SCORE.mouthfeel}>
                  <input className="score" type="text" value={judgingForm.mouthfeel.score.value} disabled />
                </HeaderBlock>

                <HeaderBlock title="Overall" outOfNumber={LIMIT_SCORE.overall} max={LIMIT_SCORE.overall}>
                  <input className="score" type="text" disabled value={judgingForm.overall.score.value} />
                </HeaderBlock>

                <HeaderBlock title="Total" outOfNumber={LIMIT_SCORE.total}>
                  <input
                    className="score"
                    type="text"
                    disabled
                    value={
                      Number(judgingForm.aroma.score.value) +
                      Number(judgingForm.appearance.score.value) +
                      Number(judgingForm.flavor.score.value) +
                      Number(judgingForm.mouthfeel.score.value) +
                      Number(judgingForm.overall.score.value)
                    }
                  />
                </HeaderBlock>
              </Row>
            </div>

            <div className="form__judge-id">
              <p className="form__judge-id_text">
                Judge ID: <span>{judgingForm.judgeID}</span>
              </p>
            </div>

            <div className="form__sponsors" style={{ marginBottom: 500 }}>
              <div>
                <h4>Sponsored By:</h4>
              </div>

              <div>
                <a href="https://ftpackaging.ca/">
                  <img alt="Sponsor" src={platinumSponsorLogo} />
                </a>
              </div>

              <div>
                <a href="https://craftcoastcanning.com/">
                  <img alt="Sponsor" src={goldSponsor1Logo} />
                </a>
              </div>

              <div>
                <a href="https://dme.ca/">
                  <img alt="Sponsor" src={goldSponsor2Logo} />
                </a>
              </div>

              <div>
                <a href="https://kegshare.ca/">
                  <img alt="Sponsor" src={goldSponsor3Logo} />
                </a>
              </div>

              <div>
                <a href="https://www.lallemand.com/">
                  <img alt="Sponsor" src={goldSponsor4Logo} />
                </a>
              </div>
            </div>

            <div>
              <Aroma disabled aromaStore={judgingForm.aroma} />
              <Appearance disabled appearanceStore={judgingForm.appearance} />
              <Flavor disabled flavorStore={judgingForm.flavor} />
              <Mouthfeel disabled mouthfeelStore={judgingForm.mouthfeel} />
              <Overall disabled overallStore={judgingForm.overall} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
