import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Result, Row } from 'antd';

import http from '../../http-common';
import Loading from '../Loading';

const PurchaseSuccessPage = () => {
  const { sessionId } = useParams();
  const [sessionData, setSessionData] = useState({});
  const [loadState, setLoadState] = useState('loading');

  useEffect(() => {
    http
      .get(`/stripe/checkout-session?sessionId=${sessionId}`)
      .then((response) => {
        setSessionData(response.data);
        setLoadState('success');
      })
      .catch((error) => {
        console.log(error);
        setSessionData(error?.response?.data?.error || 'An error occurred while retrieving payment session');
        setLoadState('fail');
      });
  }, [sessionId]);

  return (
    <Row justify="center" align="middle" style={{ height: '100vh' }}>
      {loadState === 'loading' && <Loading />}
      {loadState === 'success' && (
        <Result
          icon=<img
            src="https://ontariobrewingawards.com/wp-content/uploads/2020/09/cropped-OBA_logo_FINAL-1-1.png"
            style={{ height: '200px' }}
            alt="logo"
          />
          status="success"
          title="Payment Success!"
          subTitle={`Order ID: ${sessionData?.payment_intent}`}
          extra={[
            <Link to="/">
              <Button className="checkout-button">Go Home</Button>
            </Link>,
          ]}
        />
      )}
      {loadState === 'fail' && (
        <Result
          status="error"
          title="Payment Error!"
          subTitle={sessionData}
          extra={[
            <Link to="/">
              <Button className="checkout-button">Go Home</Button>
            </Link>,
          ]}
        />
      )}
    </Row>
  );
};

export default PurchaseSuccessPage;
