import { Button, message, Steps, Divider, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { airtableURL, authURL } from '../../FetchURL';
import CustomButton from '../CustomButton';
import http from '../../http-common';
import Step1 from '../Steps/Step1';
import Step2 from '../Steps/Step2';
import Step3 from '../Steps/Step3';
import './index.scss';

const steps = [
  {
    title: 'Login Info',
  },
  {
    title: 'Company Info',
  },
  {
    title: 'Complete Registration',
  },
];

const Signup = () => {
  const navigate = useNavigate();
  const [current, setCurrent] = useState(0);
  const [loading, setLoading] = useState(false);

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const onCancelClick = () => {
    navigate('/');
  };

  const formik = useFormik({
    initialValues: {
      userEmail: '',
      userPassword: '',
      confirmPassword: '',
      fullName: '',
      companyName: '',
      companyWebsite: '',
      phoneNumber: '',
      streetAddress: '',
      city: '',
      province: 'Ontario',
      postalCode: '',
      companyBio: '',
      companyLogo: '',
      termsOfServices: false,
      eligibilityCriteria: false,
    },
    onSubmit: async (values) => {
      setLoading(true);
      formik.setSubmitting(false);

      try {
        const response = await http.post(airtableURL + '/create-brewery', values);

        await http.post(authURL + '/signup', {
          email: values.userEmail,
          password: values.userPassword,
          breweryId: response.data.id,
        });

        setLoading(false);

        navigate('/signup-success', { replace: true });
      } catch (error) {
        setLoading(false);
        message.error(error.response.data ? error.response.data.error : 'Sign Up Error! Please try again');
        // setErrorMessage("Authentication Failed! Please try again");
      }
    },
    validationSchema: Yup.object().shape({
      userEmail: Yup.string().email('Invalid email').required('Required'),
      userPassword: Yup.string().min(8, 'Length Must Bigger than 8!').required('Required'),
      confirmPassword: Yup.string()
        .when('userPassword', {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf([Yup.ref('userPassword')], 'Both password need to be the same'),
        })
        .required('Required'),
      fullName: Yup.string().required('Required'),
      companyName: Yup.string().required('Required'),
      companyWebsite: Yup.string().required('Required'),
      phoneNumber: Yup.string().required('Required'),
      streetAddress: Yup.string().required('Required'),
      city: Yup.string().required('Required'),
      province: Yup.string().required('Required'),
      postalCode: Yup.string().required('Required'),
      termsOfServices: Yup.bool().oneOf([true], 'You need to accept the Terms of Service Agreement'),
      eligibilityCriteria: Yup.bool().oneOf([true], 'You need to accept the Eligibility Criteria'),
    }),
  });

  const nextLoginInfo = () => {
    formik.submitForm();

    if (
      formik.errors.userEmail ||
      !formik.touched.userEmail ||
      formik.errors.userPassword ||
      !formik.touched.userPassword ||
      formik.errors.confirmPassword ||
      !formik.touched.confirmPassword
    ) {
      return;
    }

    setCurrent(current + 1);
  };

  const nextCompanyInfo = () => {
    formik.submitForm();

    if (
      formik.errors.fullName ||
      !formik.touched.fullName ||
      formik.errors.companyName ||
      !formik.touched.companyName ||
      formik.errors.companyWebsite ||
      !formik.touched.companyWebsite ||
      formik.errors.phoneNumber ||
      !formik.touched.phoneNumber ||
      formik.errors.streetAddress ||
      !formik.touched.streetAddress ||
      formik.errors.city ||
      !formik.touched.city ||
      formik.errors.province ||
      !formik.touched.province ||
      formik.errors.postalCode ||
      !formik.touched.postalCode
    ) {
      return;
    }

    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  return (
    <div className="signup d-flex justify-center align-center text-center">
      {loading ? (
        <div className="w-100 absolute">
          <Spin tip="Saving" size="large">
            <div className="content" />
          </Spin>
        </div>
      ) : (
        <div className="singup__container">
          <div className="signup__container__topic">
            <label>Ontario Brewing Awards - Company Registration</label>
          </div>
          <Steps current={current} items={items} className="signup__container__steps" />
          <Divider />
          <form onSubmit={formik.handleSubmit}>
            {current === 0 ? (
              <Step1 formik={formik} />
            ) : current === 1 ? (
              <Step2 formik={formik} />
            ) : current === 2 ? (
              <Step3 formik={formik} />
            ) : (
              <div></div>
            )}
            <Divider />
            <div className="d-flex justify-between signup__container__button-container">
              <div>
                <Button className="signup__container__button-container-cancel" onClick={onCancelClick}>
                  Cancel
                </Button>
              </div>
              <div className="d-flex">
                {current > 0 && <CustomButton primary="default" label="Previous" onClick={() => prev()} />}
                <div style={{ marginRight: '15px' }}></div>
                {current < steps.length - 1 && (
                  <CustomButton
                    primary="primary"
                    label="Next"
                    onClick={() => {
                      if (current === 0) {
                        nextLoginInfo();
                        return;
                      }

                      if (current === 1) {
                        nextCompanyInfo();
                        return;
                      }
                    }}
                  />
                )}
                {current === steps.length - 1 && (
                  <CustomButton
                    primary="primary"
                    label="Complete Registration"
                    onClick={() => {
                      formik.submitForm();
                    }}
                  />
                )}
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default Signup;
