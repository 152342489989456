import React from "react";
import { Divider } from "antd";
import { useNavigate } from "react-router-dom";

import CustomButton from "../CustomButton";

import "./index.scss";

const SignUpSuccess = () => {
  const navigate = useNavigate();

  const onBackToLogin = () => {
    navigate("/", { replace: true });
  };

  return (
    <div className="signup-success h-100vh d-flex justify-center align-center text-center">
      <div className="signup-success__container">
        <img
          src="https://ontariobrewingawards.com/wp-content/uploads/2020/09/cropped-OBA_logo_FINAL-1-1.png"
          alt="logo"
          style={{ width: "100%" }}
        />
        <div className="signup-success__container__topic">
          <label>Thank you for registering</label>
        </div>
        <Divider />
        <div className="signup-success__container__activated">
          <label>Your account has been activated</label>
        </div>
        <svg
          width="60"
          height="61"
          viewBox="0 0 60 61"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M40.9574 21.009H38.2093C37.6116 21.009 37.0433 21.2961 36.6917 21.7883L27.4808 34.5617L23.3089 28.7727C22.9573 28.2863 22.3948 27.9934 21.7913 27.9934H19.0432C18.6624 27.9934 18.4397 28.427 18.6624 28.7375L25.9632 38.8625C26.1356 39.1032 26.363 39.2994 26.6264 39.4347C26.8898 39.57 27.1817 39.6406 27.4778 39.6406C27.774 39.6406 28.0658 39.57 28.3292 39.4347C28.5926 39.2994 28.82 39.1032 28.9925 38.8625L41.3324 21.7531C41.5609 21.4426 41.3382 21.009 40.9574 21.009Z"
            fill="#52C41A"
          />
          <path
            d="M30 4.07541C15.5039 4.07541 3.75 15.8293 3.75 30.3254C3.75 44.8215 15.5039 56.5754 30 56.5754C44.4961 56.5754 56.25 44.8215 56.25 30.3254C56.25 15.8293 44.4961 4.07541 30 4.07541ZM30 52.1223C17.9648 52.1223 8.20312 42.3606 8.20312 30.3254C8.20312 18.2903 17.9648 8.52853 30 8.52853C42.0352 8.52853 51.7969 18.2903 51.7969 30.3254C51.7969 42.3606 42.0352 52.1223 30 52.1223Z"
            fill="#52C41A"
          />
        </svg>
        <div className="signup-success__container__description">
          <label>
            You can now sign in with your email address and password.
          </label>
        </div>
        <div className="signup-success__container__button">
          <CustomButton
            primary="primary"
            label="Back to Login"
            onClick={onBackToLogin}
          />
        </div>
      </div>
    </div>
  );
};

export default SignUpSuccess;
