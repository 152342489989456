import { Row, Col, Button, notification, Divider } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

import http from '../../http-common';
import './index.scss';

export default function MyTicket(props) {
  const [api, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);

  const { ticket } = props;
  const navigate = useNavigate();

  const handleEmailResend = (ticketId, assigneeEmail) => {
    setIsLoading(true);
    http
      .post(`airtable/resend-email/${ticketId}`)
      .then(() => {
        setIsLoading(false);
        api.success({ message: `Email sent to the ${assigneeEmail}!`, placement: 'topRight' });
      })
      .catch((error) => {
        setIsLoading(false);
        api.error({
          message: error?.response?.data || 'Something went wrong when attempting to send the email',
          placement: 'topRight',
        });
      });
  };

  const copyTicket = (text) => {
    navigator.clipboard.writeText(text);
    api.success({ message: 'Ticket ID copied to clipboard!', placement: 'topRight' });
  };

  return (
    <div>
      {contextHolder}
      <div className="ticket-line-item" style={{ marginBottom: 15 }}>
        <Row justify={'space-between'} align={'middle'}>
          <Col>
            <b>{ticket?.fields?.['Ticket Type']}</b>
            <br />
            <p
              onClick={() => copyTicket(`#${ticket?.fields?.['Purchase ID']}`)}
              style={{ maxWidth: '25vw', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
            >
              #{ticket?.fields?.['Purchase ID']}
            </p>
          </Col>
          {ticket?.fields?.['Assignee Email'] && (
            <Col>
              <p style={{ marginBottom: 0 }}>Assigned to: {ticket?.fields?.['Assignee Name']}</p>
              {ticket?.fields?.['Assignee Email']}
            </Col>
          )}
        </Row>
        <Row gutter={10}>
          <Col>
            <Button className="checkout-button" onClick={() => props.showModal(ticket)}>
              Assign
            </Button>
          </Col>
          <Col>
            <Button
              onClick={() => {
                navigate(`/ticket-qr/${ticket?.id}`);
              }}
            >
              View
            </Button>
          </Col>
          <Col>
            <Button
              loading={isLoading}
              onClick={() => {
                handleEmailResend(ticket?.id, ticket?.fields?.['Assignee Email']);
              }}
            >
              Resend Email
            </Button>
          </Col>
        </Row>
        <Divider />
      </div>
    </div>
  );
}
