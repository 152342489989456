import { Button, Col, Collapse, Input, Modal, Row, Typography } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import autoTable from 'jspdf-autotable';
import { jsPDF } from 'jspdf';
import dayjs from 'dayjs';

import { BreweryContext, CategoriesContext, EntriesContext } from '../../context';
import { airtableURL } from '../../FetchURL';
import http from '../../http-common';

import CustomButton from '../CustomButton';
import EntryItemPage from '../EntryItem';
import Loading from '../Loading';

import RegisterEntry from '../RegisterEntry';
import './index.scss';

const { Panel } = Collapse;

const EntriesPage = () => {
  const [categories, setCategories] = useContext(CategoriesContext);
  const [entries, setEntries] = useContext(EntriesContext);
  const [brewery] = useContext(BreweryContext);

  const { eventId } = useParams();
  const [entryData, setEntryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [receiptDate, setReceiptDate] = useState('');
  const [stripeCustomerId, setStripeCustomerId] = useState('');
  const [receiptMethod, setReceiptMethod] = useState('');
  const [receiptLast4, setReceiptLast4] = useState('');
  const [editEndDate, setEditEndDate] = useState('');
  const [dropOffStartDate, setDropOffStartDate] = useState('');
  const [dropOffEndDate, setDropOffEndDate] = useState('');
  const [manualPrintingBreweryEntries, setManualPrintingBreweryEntries] = useState([]);

  const [isShoppingCardOpen, setIsShoppingCardOpen] = useState(false);
  const handleShoppingCard = () => setIsShoppingCardOpen(!isShoppingCardOpen);

  const updateShoppingData = (id, newData) => {
    const index = entryData.findIndex((item) => item.id === id);
    const updatedData = [...entryData];

    updatedData[index] = { ...updatedData[index], ...newData };

    setEntryData(updatedData);
  };

  useEffect(() => {
    if (eventId && entries?.data?.length === 0) {
      http.get(airtableURL + `/events/${eventId}`).then((res) => {
        setEditEndDate(res?.data?.fields?.['Entries Edit End']);
        setDropOffStartDate(res?.data?.fields?.['Drop Off Start']);
        setDropOffEndDate(res?.data?.fields?.['Drop Off End']);

        setEntries((prevState) => ({
          ...prevState,
          eventName: res?.data?.fields?.['Event Name'],
          data: res.data?.fields?.['Entries'],
          id: res.data.id,
          price: res.data?.fields?.['Entry Price'],
        }));
      });
    } else if (eventId) {
      http.get(airtableURL + `/events/${eventId}`).then((res) => {
        setEditEndDate(res?.data?.fields?.['Entries Edit End']);
        setDropOffStartDate(res?.data?.fields?.['Drop Off Start']);
        setDropOffEndDate(res?.data?.fields?.['Drop Off End']);
      });
    }
  }, [eventId, setEntries, entries, entryData]);

  const addEntryClick = () => {
    handleShoppingCard();
  };

  const fetchEntriesData = async () => {
    try {
      setEntryData([]);
      setLoading(true);

      const resCategories = await http.get(airtableURL + '/get-all-categories');
      if (resCategories.status !== 200) return setLoading(false);
      setCategories(resCategories.data);

      const res = await http.post(airtableURL + '/get-entries-data', {
        eventName: entries.eventName,
        breweryID: brewery.fields['brewery-id'],
      });

      if (res.status !== 200) return setLoading(false);
      setEntryData(res.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('There was an error fetch auth', error);
      return;
    }
  };

  useEffect(() => {
    fetchEntriesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entries]);

  // Get the receipt date, method, and last 4 digits
  useEffect(() => {
    entryData.map(async (entry) => {
      if (entry.paymentDate) {
        setReceiptDate(dayjs(entry.paymentDate).format('MMMM D, YYYY'));
      }

      http
        .post('/stripe/customer/search', {
          email: brewery.fields['Public Email'],
        })
        .then((res) => {
          if (res.data.data) {
            if (res.data.data.length > 0) {
              setStripeCustomerId(res.data.data[0].id);
            }
          }
        });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entryData]);

  // If manual printing searched and found, print the labels
  useEffect(() => {
    if (manualPrintingBreweryEntries.length > 0) {
      generateAveryLabels(manualPrintingBreweryEntries);
    }
  }, [manualPrintingBreweryEntries]);

  // Set the payment method and last 4 digits
  useEffect(() => {
    const getPaymentMethod = async () => {
      const paymentMethod = await http.post('/stripe/customer/payment-methods', {
        customerId: stripeCustomerId,
      });

      if (paymentMethod.data) {
        if (paymentMethod.data.data.length > 0) {
          setReceiptMethod(paymentMethod.data.data[0].card.brand);
          setReceiptLast4(paymentMethod.data.data[0].card.last4);
        }
      }
    };

    if (stripeCustomerId) {
      getPaymentMethod();
    }
  }, [stripeCustomerId]);

  const generateLabels = (breweryEntries) => {
    // var headers = createHeaders(['id', 'coin', 'game_group', 'game_name', 'game_version', 'machine', 'vlt']);
    var headers = [
      { align: 'center', id: 'beerName', name: 'beerName', padding: 10, prompt: 'Beer Name', width: 120 },
      { align: 'center', id: 'label', name: 'label', padding: 10, prompt: 'label', width: 120 },
    ];

    var data = [];

    breweryEntries.map((entry) => {
      for (let i = 0; i < 6; i++) {
        data.push({
          beerName: entry.name,
          label: entry.beerId,
        });
      }
    });

    var doc = new jsPDF({ putOnlyUsedFonts: true, orientation: 'portrait' });
    doc.setFontSize(32);
    doc.table(1, 1, data, headers, { fontSize: 24 });
    doc.save('oba-labels.pdf');
  };

  const generateAveryLabels = (breweryEntries) => {
    var headers = [
      // { align: 'center', id: 'beerName', name: 'beerName', padding: 10, prompt: 'Beer Name', width: 100 },
      { align: 'center', id: 'label', name: 'label', padding: 20, prompt: 'label', width: 125, height: 100 },
    ];

    var data = [];

    breweryEntries.map((entry) => {
      for (let i = 0; i < 2; i++) {
        data.push([`${entry.beerId}`, `${entry.beerId}`, `${entry.beerId}`, `${entry.beerId}`]);
      }
    });

    const doc = new jsPDF({ unit: 'px', putOnlyUsedFonts: true, orientation: 'portrait' });

    autoTable(doc, {
      // head: [['Labels']],
      body: data,
      theme: 'grid',
      margin: { top: 36, bottom: 36, left: 20, right: 20 },
      rowPageBreak: 'avoid',
      bodyStyles: {
        minCellWidth: 100,
        minCellHeight: 38,
        cellWidth: 100,
        cellHeight: 38,
        halign: 'center',
        valign: 'middle',
      },
    });

    // var doc = new jsPDF({ unit: 'px', putOnlyUsedFonts: true, orientation: 'portrait' });
    // doc.setFontSize(20);
    // doc.table(20, 36, data, headers);
    doc.save('oba-labels.pdf');
  };

  // If the brewery is me (brewery.fields['Public Email'] === 'romaan@thehold.co'), then let me print labels for any brewery
  const printAnyLabels = (brewery) => {
    return (
      <>
        <Input.Search
          placeholder="Enter any brewery ID to print labels"
          enterButton="Search & Print"
          allowClear
          onSearch={onMasterLabelSearch}
        />
      </>
    );
  };

  const onMasterLabelSearch = async (breweryID) => {
    try {
      const res = await http.post(airtableURL + '/get-entries-data', {
        eventName: entries.eventName,
        breweryID: breweryID,
      });
      setManualPrintingBreweryEntries(res.data);
    } catch (error) {
      console.error('There was an error fetch auth', error);
      return;
    }
  };

  // Calculate the status of the entry
  // Status: Preparing - between entry start to drop-off start
  // Status: Waiting for Arrival - between drop-off start and drop-off end + bin is empty
  // Status: Received - after drop-off start + bin is filled
  // Status: Not Received - after drop-off end + bin is empty
  // Status: Judged - after judging start
  const calculateStatus = (entry) => {
    console.log('entry', entry);

    if (entry.tastingNotes?.length > 0) {
      return 'Judged';
    } else if (entry.bin || entry.shipmentReceived) {
      return 'Received';
    } else if (dayjs().isBefore(dayjs(dropOffStartDate))) {
      return 'Preparing';
    } else if (dayjs().isAfter(dayjs(dropOffStartDate)) && dayjs().isBefore(dayjs(dropOffEndDate)) && !entry.bin) {
      return 'Waiting for Arrival';
    } else if (dayjs().isAfter(dayjs(dropOffEndDate)) && !entry.bin) {
      return 'Not Received';
    } else {
      return '';
    }
  };

  return (
    <div className="custom-container">
      <div className="custom-label__header d-flex justify-between">
        <label style={{ marginRight: 3 }}>{entries.eventName}</label>

        <div className="d-flex flex-column">
          <CustomButton
            label="+ Add Entry"
            style={{ maxWidth: 20 }}
            onClick={() => {
              addEntryClick();
            }}
          />

          <div className="d-flex d-flex justify-between entries-label" style={{ marginTop: 2 }}>
            <div></div>
            <label>This year each entry costs ${entries.price}+HST</label>
          </div>

          <div style={{ marginLeft: '20px' }}></div>
          {/* <CustomButton
            label="Shopping Cart"
            svg={true}
            onClick={() => {
              navigate('/register-entry');
            }}
          /> */}
        </div>
      </div>

      <div className="entries">
        <Row style={{ marginBottom: 12 }}>
          <Col md={12} sm={24}>
            {/* <label className="entries-label">
              Breweries may select any 4 (four) beer/SKU entries to represent their brewery for the “Brewery of the
              Year” honour. The winner is determined from the combined score of only those four selected entries based
              on a scoring system of 5 points if a selected beer earns a Gold, 3 points for earning a Silver, and 1
              point for earning a Bronze.
            </label>
            <br />
            <br /> */}
            <Collapse defaultActiveKey={['1']}>
              <Panel header="Label Printing Instructions" key="1">
                <p className="register-entry__description">
                  Starting in 2023, we are asking breweries to label their brews before submitting them for judging.
                  This will make it easier for us to keep track of every amazing entry. Please print out and attach a
                  label to each submission using the button below. The label should have a format of #-######".
                </p>
              </Panel>
            </Collapse>
          </Col>
          <Col span={12}></Col>
        </Row>
        {entryData?.length > 0 ? (
          <Button type="default" size="large" onClick={() => generateLabels(entryData)}>
            Print Labels
          </Button>
        ) : null}
        {/* {entryData?.length > 0 ? (
          <Button type="default" size="large" style={{ marginLeft: 6 }} onClick={generateReceipt}>
            Download Receipt
          </Button>
        ) : null} */}

        {brewery.fields['Public Email'] === 'romaan@thehold.co' ? (
          <>
            <br />
            <br />
            {printAnyLabels()}
          </>
        ) : null}

        <Row style={{ marginTop: 20 }}>
          <Typography.Title style={{ marginBottom: 0 }} level={3}>
            Paid Entries
          </Typography.Title>
        </Row>

        <br />
        {loading ? (
          <Loading />
        ) : entryData?.length === 0 ? (
          <label className="entries-no-label">
            <i>You have not submitted any entries yet. Click the Add Entry button in the top right to get started.</i>
          </label>
        ) : editEndDate ? (
          <>
            {/* <Table dataSource={entryData} columns={columns} /> */}
            {entryData.map((item, index) => {
              return (
                <EntryItemPage
                  name={item.name}
                  fetchShoppingData={fetchEntriesData}
                  updateShoppingData={updateShoppingData}
                  fullCategory={item.fullCategory}
                  fullCategoryValue={item.fullCategoryValue}
                  pour={item.pour}
                  boty={item.boty}
                  record={item.id}
                  description={item.description}
                  key={index}
                  categories={categories}
                  status={calculateStatus(item)}
                  type="entry"
                  editEnd={editEndDate}
                />
              );
            })}
          </>
        ) : null}
      </div>

      <Modal
        className="top-0"
        open={isShoppingCardOpen}
        width={10000}
        style={{ padding: 15 }}
        onCancel={handleShoppingCard}
        onOk={handleShoppingCard}
        footer={[]}
      >
        <RegisterEntry handleShoppingCard={handleShoppingCard} />
      </Modal>
    </div>
  );
};

export default EntriesPage;
