import { useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import AdminAssign from './components/AdminAssign';
import EntriesPage from './components/EntriesPage';
import EventsPage from './components/EventsPage';
import Login from './components/Login';
import OrderHistory from './components/OrderHistory';
import PurchaseSuccessPage from './components/PurchaseSuccessPage';
import RegisterEntry from './components/RegisterEntry';
import SavedEntries from './components/SavedEntries';
import { ScanTicketPage } from './components/ScanTicketPage';
import Settings from './components/Settings';
import ShoppingCart from './components/ShoppingCart';
import Signup from './components/Signup';
import SignUpSuccess from './components/Signup/SignUpSuccess';
import TastingNoteViewPage from './components/TastingNoteViewPage';
import TastingNotesListPage from './components/TastingNotesListPage';
import TicketQuickBuyPage from './components/TicketQuickBuyPage';
import TicketRegistrationPage from './components/TicketRegistrationPage';
import { ViewTicketPage } from './components/ViewTicketPage';
import WinnersPage from './components/WinnersPage';
import { UserContext } from './context';
import UsersPage from './components/UsersPage';

function RoutesComp() {
  const [userContext, setUserContext] = useContext(UserContext);

  const handleNextPath = () => {
    const currentUrl = window.location.href;

    if (currentUrl.includes('/users?requestInvitation=')) {
      sessionStorage.setItem('nextPath', currentUrl);
    }
  };

  useEffect(() => {
    handleNextPath();
  }, []);

  return (
    <>
      <Routes>
        {userContext._id !== '' ? (
          <>
            <Route path="/" element={<EventsPage />} />
            <Route path="/entries/:eventId" element={<EntriesPage />} />
            <Route path="/register-entry/:eventId" element={<RegisterEntry />} />
            <Route path="/shopping-cart" element={<ShoppingCart />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/order-history" element={<OrderHistory />} />
            <Route path="/ticket-registration/:eventId" element={<TicketRegistrationPage />} />
            <Route path="/ticket-qr/:ticketId" element={<ViewTicketPage />} />
            <Route path="/scan/:ticketID" element={<ScanTicketPage />} />
            <Route path="/tasting-notes" element={<TastingNotesListPage />} />
            <Route path="/tasting-notes/:tastingNoteId" element={<TastingNoteViewPage />} />
            <Route path="/saved-entries" element={<SavedEntries marginTop={25} />} />
            <Route path="/winners" element={<WinnersPage />} />
            <Route path="/users" element={<UsersPage />} />
            {userContext.isAdmin && <Route path="/admin-assign" element={<AdminAssign />} />}
            {userContext.fetched && <Route path="*" element={<Navigate replace to="/" />} />}
          </>
        ) : (
          <>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/signup-success" element={<SignUpSuccess />} />
            <Route path="/ticket-quick-buy" element={<TicketQuickBuyPage />} />
            {userContext.fetched && <Route path="*" element={<Navigate replace to="/login" />} />}
          </>
        )}
        <Route path="/purchase-success/:sessionId" element={<PurchaseSuccessPage />} />
      </Routes>
    </>
  );
}

export default RoutesComp;
