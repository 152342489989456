import { useEffect, useState, useContext } from 'react';
import { Tabs, Select, Divider, Row, Col, Spin } from 'antd';
import { BreweryContext, UserContext } from '../../context';
import { airtableURL } from '../../FetchURL';
import AssignTicketModal from '../AssignTicketModal';
import http from '../../http-common';
import MyTicket from '../MyTicket';

import './index.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { set } from 'mongoose';

export default function RegistrationWidget({ eventData, remainingTickets }) {
  const { eventId } = useParams();
  const [brewery, setBrewery] = useContext(BreweryContext);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [user] = useContext(UserContext);
  // const [conferenceQuantity, setConferenceQuantity] = useState(1);
  // const [earlyQuantity, setEarlyQuantity] = useState(0);
  // const [exhibitionQuantity, setExhibitionQuantity] = useState(0);
  const [galaQuantity, setGalaQuantity] = useState(0);
  const [myTicketsLoading, setMyTicketsLoading] = useState(true); // [TODO
  const [myTickets, setMyTickets] = useState([]);
  const [modalTicket, setModalTicket] = useState({});
  const navigate = useNavigate();
  const query = new URLSearchParams(window.location.search);

  console.log(`user`, user);

  useEffect(() => {
    if (brewery.fields && eventData.fields) {
      http
        .get(airtableURL + `/attendees/${brewery.fields['Public Email']}/${eventData?.fields?.['Event Name']}`)
        .then((res) => {
          console.log(`res`, res.data);
          if (res.data.length === 0) {
            setGalaQuantity(1);
            setMyTicketsLoading(false);
          }
          setMyTickets(res.data);
        });
    }
  }, [brewery, eventData]);

  useEffect(() => {
    if (myTickets.length > 0) {
      setMyTicketsLoading(false);
    }
    if (myTickets.length >= 2) {
      setGalaQuantity(0);
    } else {
      setGalaQuantity(1);
    }
  }, [myTickets]);

  // const handleConferenceTicketChange = (value) => {
  //   console.log(`selected ${value}`);
  //   setConferenceQuantity(value);
  // };

  // const handleEarlyChange = (value) => {
  //   console.log(`selected ${value}`);
  //   setEarlyQuantity(value);
  // };

  // const handleExhibitionChange = (value) => {
  //   console.log(`selected ${value}`);
  //   setExhibitionQuantity(value);
  // };

  const handleGalaChange = (value) => {
    console.log(`selected ${value}`);
    setGalaQuantity(value);
  };

  const showModal = (ticket) => {
    setModalTicket(ticket);
    setIsAssignModalOpen(true);
  };

  const handleOk = () => {
    setIsAssignModalOpen(false);

    if (brewery.fields) {
      http
        .get(airtableURL + `/attendees/${brewery.fields['Public Email']}/${eventData?.fields?.['Event Name']}`)
        .then((res) => {
          console.log(`res`, res.data);
          setMyTickets(res.data);
        });
    }
  };

  const handleCancel = () => {
    setIsAssignModalOpen(false);
  };

  const items = [
    {
      key: '1',
      label: `Purchase Tickets`,
      children: (
        <>
          <div className="ticket-line-item">
            {/* <Row>
              <Col span={18}>
                <b>Early Bird Registration</b>
                <br />
                <b>$350.00</b>
                <p>Full access to the entire 3-day event.</p>
              </Col>
              <Col span={6}>
                <Select
                  placeholder="Select quantity"
                  style={{ width: 180 }}
                  onChange={handleEarlyChange}
                  defaultValue={1}
                >
                  <Select.Option value={0}>0</Select.Option>
                  <Select.Option value={1}>1</Select.Option>
                  <Select.Option value={2}>2</Select.Option>
                  <Select.Option value={3}>3</Select.Option>
                  <Select.Option value={4}>4</Select.Option>
                  <Select.Option value={5}>5</Select.Option>
                  <Select.Option value={6}>6</Select.Option>
                  <Select.Option value={7}>7</Select.Option>
                  <Select.Option value={8}>8</Select.Option>
                  <Select.Option value={9}>9</Select.Option>
                  <Select.Option value={10}>10</Select.Option>
                </Select>
              </Col>
            </Row> */}

            <Row>
              <Col span={18}>
                <b>OBA 2023 Gala Admission</b>
                <br />
                <b>$50.00</b>
                <p>Full access to the event. No longer limited.</p>
              </Col>
              <Col flex="auto">
                {myTicketsLoading ? (
                  <Spin />
                ) : (
                  <Select
                    placeholder="Select quantity"
                    style={{ width: '100%' }}
                    defaultValue={1}
                    onChange={handleGalaChange}
                    max={2}
                  >
                    <Select.Option value={0}>0</Select.Option>
                    <Select.Option value={1} disabled={remainingTickets < 1}>
                      1
                    </Select.Option>
                    <Select.Option value={2} disabled={remainingTickets < 2}>
                      2
                    </Select.Option>
                    <Select.Option value={3} disabled={remainingTickets < 3}>
                      3
                    </Select.Option>
                    <Select.Option value={4} disabled={remainingTickets < 4}>
                      4
                    </Select.Option>
                    <Select.Option value={5} disabled={remainingTickets < 5}>
                      5
                    </Select.Option>
                    <Select.Option value={6} disabled={remainingTickets < 6}>
                      6
                    </Select.Option>
                    <Select.Option value={7} disabled={remainingTickets < 7}>
                      7
                    </Select.Option>
                    <Select.Option value={8} disabled={remainingTickets < 8}>
                      8
                    </Select.Option>
                    <Select.Option value={9} disabled={remainingTickets < 9}>
                      9
                    </Select.Option>
                    <Select.Option value={10} disabled={remainingTickets < 10}>
                      10
                    </Select.Option>
                  </Select>
                )}
              </Col>
            </Row>
            <Divider />
          </div>

          {myTicketsLoading ? (
            <Spin />
          ) : (
            <Row align="middle" justify="space-between">
              {galaQuantity > 0 ? (
                <Col>
                  <b>Subtotal: ${(galaQuantity * 50).toFixed(2)}</b>
                </Col>
              ) : null}
              <Col>
                <form
                  action={`${process.env.REACT_APP_BACKEND_BASE_URL}/stripe/create-checkout-session`}
                  // action={`http://localhost:5000/stripe/create-checkout-session`}
                  method="POST"
                >
                  {/* <input name="quantityEarly" value={earlyQuantity} type="hidden" /> */}
                  {/* <input name="conferenceQuantity" value={conferenceQuantity} type="hidden" /> */}
                  {/* <input name="quantityExhibition" value={exhibitionQuantity} type="hidden" /> */}
                  <input name="quantityGala" value={galaQuantity} type="hidden" />
                  <input name="company" value={brewery.fields['Brewery Name']} type="hidden" />
                  <input name="eventId" value={eventId} type="hidden" />
                  {/* <input name="referralCode" value={referralCode} type="hidden" /> */}

                  {user.role === 'Admin' ? (
                    galaQuantity > 0 ? (
                      remainingTickets > 0 ? (
                        <button className="checkout-button" type="submit">
                          Checkout
                        </button>
                      ) : (
                        <p style={{ color: 'red' }}>No more tickets available.</p>
                      )
                    ) : null
                  ) : (
                    <p style={{ color: 'red' }}>Only Admin accounts my buy tickets.</p>
                  )}
                </form>
              </Col>
            </Row>
          )}
        </>
      ),
    },
    {
      key: '2',
      label: `My Tickets`,
      children: (
        <>
          {myTickets.length > 0
            ? myTickets.map((ticket) => <MyTicket key={ticket.id} ticket={ticket} showModal={showModal} />)
            : null}
        </>
      ),
    },
  ];

  const onChange = (key) => {
    navigate(window.location.pathname + `?tab=${key}`);
    console.log(key);
  };

  return (
    <div>
      <AssignTicketModal isModalOpen={isAssignModalOpen} onOk={handleOk} onCancel={handleCancel} ticket={modalTicket} />
      <Tabs className="tickets-widget" defaultActiveKey={query.get('tab') || '1'} items={items} onChange={onChange} />
    </div>
  );
}
