import { Checkbox, Col, Row, Slider, Space } from 'antd';

import { LIMIT_SCORE } from '../../../consts/LIMIT_SCORE';
import BlockSlider from '../../JudgingFormComponents/BlockSlider';
import ScoringGuide from './ScoringGuide';

export default function Overall({ overallStore }) {
  return (
    <>
      <div className="tastingnote__section form__section w-full" style={{ minHeight: '1000px' }}>
        <Row className="form__section_title">
          <Col xs={12}>
            <h1>Overall</h1>
          </Col>

          <Col xs={12} style={{ marginBottom: 2 }}>
            <Space align="end" direction="vertical" size={1} className="w-full">
              <Row>
                <input className="score" disabled={true} value={overallStore.score.value} type="text" />
                <Space align="end">
                  <p className="text-gray" style={{ marginLeft: 4, textAlign: 'center' }}>
                    Out of {LIMIT_SCORE.overall}
                  </p>
                </Space>
              </Row>
            </Space>
          </Col>
        </Row>

        <Row style={{ marginTop: 15 }}>
          <BlockSlider title="Overall score" score={overallStore.score.value}>
            <Slider
              disabled={true}
              value={overallStore.score.value}
              defaultValue={0}
              style={{ marginTop: 18 }}
              min={0}
              max={LIMIT_SCORE.overall}
            />
          </BlockSlider>
        </Row>

        <ScoringGuide />

        <Row className="section-margin-y">
          <BlockSlider title="Stylistic Accuracy" score={overallStore.stylisticAccuracy.value}>
            <Slider
              disabled={true}
              value={overallStore.stylisticAccuracy.value}
              defaultValue={0}
              style={{ marginTop: 18 }}
              min={0}
              max={5}
            />
          </BlockSlider>

          <BlockSlider title="Technical Merit" score={overallStore.technicalMerit.value}>
            <Slider
              disabled={true}
              value={overallStore.technicalMerit.value}
              defaultValue={0}
              style={{ marginTop: 18 }}
              min={0}
              max={5}
            />
          </BlockSlider>

          <BlockSlider title="Intangibles" score={overallStore.intangibles.value}>
            <Slider
              disabled={true}
              value={overallStore.intangibles.value}
              defaultValue={0}
              style={{ marginTop: 18 }}
              min={0}
              max={5}
            />
          </BlockSlider>
        </Row>

        <Row className="section-margin-y">
          <Col xs={24} lg={8}>
            <Space style={{ marginBottom: 16 }}>
              <h3 className="title">Flaws</h3>
            </Space>

            <Space size={[8, 18]} wrap className="margin-y">
              <Row>
                <Col span={24}>
                  <Checkbox
                    disabled={true}
                    value="I would finish this sample"
                    checked={overallStore.flaws.iWouldFinish}
                  >
                    I would finish this sample
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    disabled={true}
                    value="I would drink a pint of this beer"
                    checked={overallStore.flaws.iWouldDrink}
                  >
                    I would drink a pint of this beer
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    disabled={true}
                    value="I would pay money for this beer"
                    checked={overallStore.flaws.iWouldPay}
                  >
                    I would pay money for this beer
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    disabled={true}
                    value="Please send me the recipe!!"
                    checked={overallStore.flaws.pleaseSendRecipe}
                  >
                    Please send me the recipe!!
                  </Checkbox>
                </Col>
              </Row>
            </Space>
          </Col>
        </Row>

        <Row className="section-margin-y">
          <Col xs={24} lg={8}>
            <Space style={{ marginBottom: 16 }}>
              <h3 className="title">Comments</h3>
            </Space>

            <p disabled={true} style={{ marginBottom: 8 }} className="w-full comments">
              {overallStore.comments.value}
            </p>
          </Col>
        </Row>
      </div>
    </>
  );
}
