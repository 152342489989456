import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Result, Space, Spin } from 'antd';

import { airtableURL } from '../../FetchURL';
import http from '../../http-common';

export function ScanTicketPage() {
  const { ticketID } = useParams();
  const [scanResult, setScanResult] = useState({ status: 'pending', msg: '' });

  useEffect(() => {
    http
      .post(airtableURL + `/scan-ticket`, {
        ticketID: ticketID,
      })
      .then((res) => {
        console.log(res);
        if (res.status !== 200) setScanResult({ status: 'error', msg: 'Could not scan the ticket please try again!' });
        setScanResult(res.data);
      })
      .catch((error) => {
        setScanResult({
          status: 'error',
          msg: error?.response?.data?.msg || 'Could not scan the ticket please try again!',
        });
      });
  }, [ticketID]);

  return (
    <Space
      direction="vertical"
      size="middle"
      align="center"
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: '80%',
      }}
    >
      {scanResult.status === 'pending' && <Spin tip="Checking the ticket" size="large" />}
      {scanResult.status === 'fail' && (
        <Result
          status="error"
          title={scanResult.msg}
          subTitle={
            <>
              <h2>Ticket Details</h2>
              <p>
                <strong>Ticket Type:</strong> {scanResult.ticket?.fields?.['Ticket Type']}
              </p>
              <p>
                <strong>Attendee Type:</strong> {scanResult.ticket?.fields?.['Attendee Type']}
              </p>
              <p>
                <strong>Name:</strong> {scanResult.ticket?.fields?.['Assignee Name']}
              </p>
              <p>
                <strong>Email:</strong> {scanResult.ticket?.fields?.['Assignee Email']}
              </p>
            </>
          }
        />
      )}
      {scanResult.status === 'success' && (
        <Result
          status="success"
          title="Checked In"
          subTitle={
            <>
              <h2>Ticket Details</h2>
              <p>
                <strong>Ticket Type:</strong> {scanResult.ticket?.fields?.['Ticket Type']}
              </p>
              <p>
                <strong>Attendee Type:</strong> {scanResult.ticket?.fields?.['Attendee Type']}
              </p>
              <p>
                <strong>Name:</strong> {scanResult.ticket?.fields?.['Assignee Name']}
              </p>
              <p>
                <strong>Email:</strong> {scanResult.ticket?.fields?.['Assignee Email']}
              </p>
            </>
          }
        />
      )}
      {scanResult.status === 'error' && <Result status="403" title={scanResult.msg} />}
    </Space>
  );
}
