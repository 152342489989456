import React, { useState, useContext } from 'react';
import { Modal, Divider, message } from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import CustomButton from '../CustomButton';
import FormInput from '../FormInput';
import Loading from '../Loading';

import { BreweryContext, UserContext } from '../../context';
import { authURL } from '../../FetchURL';
import http from '../../http-common';

import './index.scss';

const ResetPasswordModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [brewery] = useContext(BreweryContext);
  const [user] = useContext(UserContext);

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    onSubmit: async (values) => {
      setLoading(true);
      formik.setSubmitting(false);
      try {
        await http.patch(authURL + `/${user._id}/changePassword`, {
          email: brewery.fields['Public Email'],
          password: values.oldPassword,
          newPassword: values.newPassword,
        });
        setLoading(false);
        message.success('Update Success');
      } catch (error) {
        setLoading(false);
        message.error('Failed');
        // setErrorMessage("Authentication Failed! Please try again");
      }
    },
    validationSchema: Yup.object().shape({
      oldPassword: Yup.string().required('Required'),
      newPassword: Yup.string().min(8, 'Length Must Bigger than 8!').required('Required'),
      confirmPassword: Yup.string()
        .when('newPassword', {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf([Yup.ref('newPassword')], 'Both password need to be the same'),
        })
        .required('Required'),
    }),
  });

  return (
    <>
      <Modal
        title="Update Password"
        open={props.isModalOpen}
        onOk={props.handleOk}
        onCancel={props.handleCancel}
        className="update-password-modal"
        footer={[
          <CustomButton primary="" onClick={props.handleCancel} label="Cancel" key="key" />,
          <CustomButton primary="primary" onClick={formik.handleSubmit} loading={loading} label="Save" key="submit" />,
        ]}
      >
        <Divider />
        {loading ? (
          <div style={{ height: '300px' }}>
            <Loading />
          </div>
        ) : (
          <>
            <FormInput
              id="oldPassword"
              name="oldPassword"
              onChange={formik.handleChange}
              value={formik.values.oldPassword}
              placeHolder="Old Password"
              password="password"
            />
            {formik.errors.oldPassword && formik.touched.oldPassword && (
              <div className="custom__input-error">{formik.errors.oldPassword}</div>
            )}
            <FormInput
              id="newPassword"
              name="newPassword"
              onChange={formik.handleChange}
              value={formik.values.newPassword}
              placeHolder="New Password"
              password="password"
            />
            {formik.errors.newPassword && formik.touched.newPassword && (
              <div className="custom__input-error">{formik.errors.newPassword}</div>
            )}
            <FormInput
              id="confirmPassword"
              name="confirmPassword"
              onChange={formik.handleChange}
              value={formik.values.confirmPassword}
              placeHolder="Confirm Password"
              password="password"
            />
            {formik.errors.confirmPassword && formik.touched.confirmPassword && (
              <div className="custom__input-error">{formik.errors.confirmPassword}</div>
            )}
          </>
        )}
        <Divider />
      </Modal>
    </>
  );
};

export default ResetPasswordModal;
