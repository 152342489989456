import { BreweryContext, UserContext } from '../context';
import { useContext, useEffect } from 'react';
import { authURL } from '../FetchURL';
import http from '../http-common';

export default function useLogOut() {
  const [userSession, setUserSession] = useContext(UserContext);
  const [brewery] = useContext(BreweryContext);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const logout = async () => {
    await http.get(authURL + '/logout');
    setUserSession({ _id: '', email: '' });
    window.location.href = '/login';
  };

  useEffect(() => {
    if (brewery) {
      if (!brewery.fields['brewery-id']) {
        logout();
      }
    }
  }, [brewery, logout]);

  return { brewery };
}
