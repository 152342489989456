import { message, Modal, Row } from 'antd';
import React, { useState } from 'react';

import httpCommon from '../../http-common';
import CustomButton from '../CustomButton';
import { emailURL } from '../../FetchURL';

export default function EmailDomainModal({
  isEmailDomainModalOpen,
  handleEmailDomainModal,
  emailAdminUser,
  emailUser,
  breweryAdminData,
}) {
  const [messageApi, contextHolder] = message.useMessage();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSendRequestInvitation = async () => {
    try {
      setIsSubmitting(true);

      await httpCommon.post(emailURL + '/request-invitation/', {
        emailAdminUser,
        emailUser,
      });

      messageApi.success('Request invitation has been sent!');

      handleEmailDomainModal();
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Modal
        title="Notification"
        open={isEmailDomainModalOpen}
        onCancel={handleEmailDomainModal}
        footer={[
          <Row justify="end">
            <CustomButton primary="primary" loading={isSubmitting} onClick={handleSendRequestInvitation} label="Send" />
          </Row>,
        ]}
      >
        <p>
          There is no account with this email, but your brewery already has an account (
          {breweryAdminData?.fields['Brewery Name (from Brewery)']}). Click the button below to request access to from
          your admin ({emailAdminUser})
        </p>
      </Modal>

      {contextHolder}
    </>
  );
}
