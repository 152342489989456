import { Space, Spin } from 'antd';

const Loading = () => {
  return (
    <Space direction="vertical" className="w-100 h-100 d-flex justify-center">
      <Spin tip="Loading" size="large">
        <div className="content" />
      </Spin>
    </Space>
  );
};

export default Loading;
