import CustomButton from '../CustomButton';
import { Modal } from 'antd';
import './index.scss';

export const PrivacySettingsModal = (props) => {
  return (
    <Modal
      title="Privacy Statement"
      open={props.isPrivacyModalOpen}
      onOk={props.handlePrivacyOk}
      onCancel={props.handlePrivacyCancel}
      className="modal"
      bodyStyle={{ overflowY: 'auto' }}
      key="Privacy Modal"
      footer={[
        <div key="none"></div>,
        <CustomButton primary="primary" onClick={props.handlePrivacyCancel} label="Close" key="privacyclose" />,
      ]}
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon">
        <path
          d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12 20.7188C7.18594 20.7188 3.28125 16.8141 3.28125 12C3.28125 7.18594 7.18594 3.28125 12 3.28125C16.8141 3.28125 20.7188 7.18594 20.7188 12C20.7188 16.8141 16.8141 20.7188 12 20.7188Z"
          fill="#AD6800"
        />
        <path
          d="M10.875 7.875C10.875 8.17337 10.9935 8.45952 11.2045 8.6705C11.4155 8.88147 11.7016 9 12 9C12.2984 9 12.5845 8.88147 12.7955 8.6705C13.0065 8.45952 13.125 8.17337 13.125 7.875C13.125 7.57663 13.0065 7.29048 12.7955 7.0795C12.5845 6.86853 12.2984 6.75 12 6.75C11.7016 6.75 11.4155 6.86853 11.2045 7.0795C10.9935 7.29048 10.875 7.57663 10.875 7.875ZM12.5625 10.5H11.4375C11.3344 10.5 11.25 10.5844 11.25 10.6875V17.0625C11.25 17.1656 11.3344 17.25 11.4375 17.25H12.5625C12.6656 17.25 12.75 17.1656 12.75 17.0625V10.6875C12.75 10.5844 12.6656 10.5 12.5625 10.5Z"
          fill="#AD6800"
        />
      </svg>

      <label>
        SECTION 1 - WHAT DO WE DO WITH YOUR INFORMATION? When you purchase something from our site, as part of the
        buying and selling process, we collect the personal information you give us such as your name, address and email
        address. When you browse our store, we also automatically receive your computer’s internet protocol (IP) address
        in order to provide us with information that helps us learn about your browser and operating system. With your
        permission, we may send you emails about the Ontario Brewing Awards, Ontario Brewing Awards and Conference, TAPS
        Media Inc. products and other updates.
        <br />
        <br /> SECTION 2 - CONSENT When you provide us with personal information to complete a transaction, we imply
        that you consent to our collecting it and using it for that specific reason only. If we ask for your personal
        information for any other reason we will either ask you directly for your expressed consent, or provide you with
        an opportunity to say no. If after you opt-in you change your mind, you may withdraw your consent for us to
        contact you, use or disclose your information at any time by contacting us at info@tapsmedia.ca or mailing us at
        Taps Media Inc., 110 Progress Rd., Gravenhurst, ON P1P 1X4
        <br />
        <br /> SECTION 3 - DISCLOSURE We may disclose your personal information if we are required by law to do so or if
        you violate our Terms of Service.
        <br />
        <br /> SECTION 4 - THIRD-PARTY SERVICES In general, the third-party providers used by us will only collect, use
        and disclose your information to the extent necessary to allow them to perform the services they provide to us.
        However, certain third-party service providers, such as payment gateways and other payment transaction
        processors (e.g. PayPal Holdings Inc.), have their own privacy policies in respect to the information we are
        required to provide to them for your purchase-related transactions. For these providers, we recommend that you
        read their privacy policies so you can understand the manner in which your personal information will be handled
        by these providers. In particular, remember that certain providers may be located in or have facilities that are
        located a different jurisdiction than either you or us. So if you elect to proceed with a transaction that
        involves the services of a third-party service provider, then your information may become subject to the laws of
        the jurisdiction(s) in which that service provider or its facilities are located. As an example, if you are
        located in Canada and your transaction is processed by a payment gateway located in the United States, then your
        personal information used in completing that transaction may be subject to disclosure under United States
        legislation, including the Patriot Act. Once you leave our store’s website or are redirected to a third-party
        website or application, you are no longer governed by this Privacy Policy or our website’s Terms of Service.
        <br />
        <br /> SECTION 5 - SECURITY To protect your personal information, we take reasonable precautions and follow
        industry best practices to make sure it is not inappropriately lost, misused, accessed, disclosed, altered or
        destroyed. Although no method of transmission over the Internet or electronic storage is 100% secure, we
        implement generally accepted industry standards to protect your information.
        <br />
        <br /> SECTION 6 - AGE OF CONSENT By using this site, you represent that you are at least the age of majority in
        your state or province of residence.
        <br />
        <br /> SECTION 7 - CHANGES TO THIS PRIVACY POLICY We reserve the right to modify this privacy policy at any
        time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting
        on the website. If we make material changes to this policy, we will notify you here that it has been updated, so
        that you are aware of what information we collect, how we use it, and under what circumstances, if any, we use
        and/or disclose it. If our site is acquired or merged with another company, your information may be transferred
        to the new owners so that we may continue to sell products to you. QUESTIONS AND CONTACT INFORMATION If you
        would like to: access, correct, amend or delete any personal information we have about you, register a
        complaint, or simply want more information contact our Privacy Policy, contact us at info@tapsmedia.ca or mail
        us at Taps Media Inc., 110 Progress Rd., Gravenhurst, ON P1P 1X4.
      </label>
    </Modal>
  );
};

export const EligibleCriteriasModal = (props) => {
  return (
    <Modal
      title="Eligible Criteria"
      open={props.isEligibleModalOpen}
      onOk={props.handleEligibleOk}
      onCancel={props.handleEligibleCancel}
      className="modal"
      bodyStyle={{ overflowY: 'auto' }}
      key="Eligible Modal"
      footer={[
        <div key="none"></div>,
        <CustomButton primary="primary" onClick={props.handleEligibleCancel} label="Close" key="eligibleclose" />,
      ]}
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon">
        <path
          d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12 20.7188C7.18594 20.7188 3.28125 16.8141 3.28125 12C3.28125 7.18594 7.18594 3.28125 12 3.28125C16.8141 3.28125 20.7188 7.18594 20.7188 12C20.7188 16.8141 16.8141 20.7188 12 20.7188Z"
          fill="#AD6800"
        />
        <path
          d="M10.875 7.875C10.875 8.17337 10.9935 8.45952 11.2045 8.6705C11.4155 8.88147 11.7016 9 12 9C12.2984 9 12.5845 8.88147 12.7955 8.6705C13.0065 8.45952 13.125 8.17337 13.125 7.875C13.125 7.57663 13.0065 7.29048 12.7955 7.0795C12.5845 6.86853 12.2984 6.75 12 6.75C11.7016 6.75 11.4155 6.86853 11.2045 7.0795C10.9935 7.29048 10.875 7.57663 10.875 7.875ZM12.5625 10.5H11.4375C11.3344 10.5 11.25 10.5844 11.25 10.6875V17.0625C11.25 17.1656 11.3344 17.25 11.4375 17.25H12.5625C12.6656 17.25 12.75 17.1656 12.75 17.0625V10.6875C12.75 10.5844 12.6656 10.5 12.5625 10.5Z"
          fill="#AD6800"
        />
      </svg>

      <label>
        The competition is open to all majority Ontario-owned commercial breweries whose products are produced and
        packaged in Canada
        <br />
        <br />
        ENTRY LIMITS: There are no entry limits this year.
        <br />
        <br />
        CATEGORY LIMITS: Please note that categories with less than 5 entries may be eliminated or compressed into other
        categories. In the event that this happens, you will be contacted.
      </label>
    </Modal>
  );
};
