import { useContext, useEffect, useState } from 'react';
import { Tag, message, Input, Table } from 'antd';

import { airtableURL, authURL, emailURL } from '../../FetchURL';
import { monthNames } from '../../common/Variable';
import { BreweryContext } from '../../context';
import { CalcTime } from '../../common/utils';
import http from '../../http-common';
import Loading from '../Loading';
import './index.scss';

const { Search } = Input;

const OrderHistory = () => {
  const [brewery] = useContext(BreweryContext);

  const [historyData, setHistoryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [emailData, setEmailData] = useState([]);

  console.log('tableData', tableData);

  const columns = [
    {
      title: 'ORDER #',
      dataIndex: 'order',
      key: 'order',
    },
    {
      title: 'DATE',
      dataIndex: 'date',
      key: 'date',
      render: (text, record) => {
        if (text == 'undefined NaN,NaN') return <span>-</span>;
        else return <span>{text}</span>;
      },
    },
    {
      title: 'ENTRY NAME',
      dataIndex: 'entry_name',
      key: 'entry_name',
    },
    {
      title: 'STATUS',
      key: 'status',
      dataIndex: 'status',
      render: (_, { status }) => (
        <>
          {status.map((item) => {
            let color = 'green';
            if (item === 'Refunded') {
              color = 'volcano';
            }
            return (
              <Tag color={color} key={item}>
                {item.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: 'PRICE',
      dataIndex: 'price',
      key: 'price',
    },
    // {
    //   key: "action",
    //   dataIndex: "action",
    //   render: (_, { action }, record) => (
    //     <Button
    //       className="refund-button"
    //       disabled={action}
    //       onClick={() => {
    //         sendEmail(record);
    //       }}
    //     >
    //       Refund
    //     </Button>
    //   ),
    // },
  ];

  const sendEmail = (number) => {
    const sendData = emailData.filter((e) => e.order === tableData[number].order);
    const requestRefund = async () => {
      try {
        const res = await http.get(authURL + '/user');
        sendData.push([res.data.email]);
        http.post(emailURL + '/refund-request', {
          data: sendData,
        });
        message.success('Brewery Refund Requested');
      } catch (error) {
        setLoading(false);
        console.error('There was an error fetch auth', error);
        return;
      }
    };
    requestRefund();
  };

  const onSearch = (value) => {
    setTableData([]);
    for (let i = 0; i < historyData.length; i++) {
      if (
        historyData[i].order.toString().includes(value) |
        historyData[i].date.includes(value) |
        historyData[i].entry_name.includes(value) |
        historyData[i].status.includes(value) |
        historyData[i].price.includes(value)
      )
        setTableData((t) => [...t, historyData[i]]);
    }
  };

  const getTableData = async (fetchData) => {
    for (let i = 0; i < fetchData.data.length; i++) {
      const data = {
        key: `${i + 1}`,
        order: i + 1,
        date:
          monthNames[new Date(fetchData.data[i].date).getMonth()] +
          ' ' +
          new Date(fetchData.data[i].date).getDate() +
          ',' +
          new Date(fetchData.data[i].date).getFullYear(),
        entry_name: fetchData.data[i].record.fields['beer name'],
        status: [fetchData.data[i].record.fields['Payment Status']],
        price: `$${fetchData.data[i].price}`,
        action:
          fetchData.data[i].record.fields['Payment Status'] === 'Refunded' ? true : CalcTime(fetchData.data[i].date),
      };
      setHistoryData((t) => [...t, data]);
      setTableData((t) => [...t, data]);
      const newEmailData = {
        order: i + 1,
        entry_name: fetchData.data[i].record.fields['beer name'],
        price: `$${fetchData.data[i].price}`,
        event_name: fetchData.data[i].event,
        description: fetchData.data[i].record.fields['Description'],
        full_category:
          fetchData.data[i].record.fields['category code'][0] +
          '-' +
          fetchData.data[i].record.fields['category name'][0],
        pour: fetchData.data[i].record.fields['pour'],
      };
      setEmailData((t) => [...t, newEmailData]);
    }
  };

  useEffect(() => {
    const fetchOrderHistory = async () => {
      setLoading(true);
      try {
        const res = await http.get(airtableURL + `/get-order-history/${brewery.fields['brewery-id']}`);
        await getTableData(res);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error('There was an error fetch auth', error);
        return;
      }
    };
    fetchOrderHistory();
  }, []);

  return (
    <div className="custom-container">
      <div className="custom-label__header">
        <label>Order History</label>
      </div>
      <div className="order-history">
        <Search
          placeholder="Search"
          allowClear
          onSearch={onSearch}
          style={{
            width: 200,
            marginBottom: '30px',
          }}
        />
        {loading ? <Loading /> : <Table columns={columns} dataSource={tableData} />}
      </div>
    </div>
  );
};

export default OrderHistory;
