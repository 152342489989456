import { Col, Row, Space, Typography } from "antd";
const { Paragraph } = Typography;

export default function ScoringGuide({ flaws }) {
  return (
    <Row style={{ marginTop: 20, fontSize: "20px" }}>
      <Col>
        <Space className="margin-x" style={{ marginLeft: 0 }}>
          <Typography>
            <Paragraph strong>Scoring Guide:</Paragraph>
          </Typography>
        </Space>
        <Space className="margin-x">
          <Typography>
            <Paragraph>0 - N/A</Paragraph>
          </Typography>
        </Space>
        <Space className="margin-x">
          <Typography>
            <Paragraph>1 - Low</Paragraph>
          </Typography>
        </Space>
        <Space className="margin-x">
          <Typography>
            <Paragraph>{flaws ? 2 : 3} - Med</Paragraph>
          </Typography>
        </Space>
        <Space className="margin-x">
          <Typography>
            <Paragraph>{flaws ? 3 : 5} - High</Paragraph>
          </Typography>
        </Space>
      </Col>
    </Row>
  );
}
