/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Col, Modal, Row } from 'antd';
import { useEffect, useState } from 'react';

import { EVENT_NAME } from '../../consts/EVENT_CONSTS';
import { airtableURL } from '../../FetchURL';
import http from '../../http-common';
import HelpModal from '../HelpModal';
import './index.scss';

const CustomFooter = () => {
  const [isTOSModalOpen, setIsTOSModalOpen] = useState(false);
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
  const [isEligibilityModalOpen, setIsEligibilityModalOpen] = useState(false);

  const handleTOSModal = () => setIsTOSModalOpen(!isTOSModalOpen);
  const handleRefundModal = () => setIsRefundModalOpen(!isRefundModalOpen);
  const handlePrivacyModal = () => setIsPrivacyModalOpen(!isPrivacyModalOpen);
  const handleEligibilityModal = () => setIsEligibilityModalOpen(!isEligibilityModalOpen);

  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const handleHelpModal = () => setIsHelpModalOpen(!isHelpModalOpen);

  const [tosText, setTosText] = useState('');
  const [refundText, setRefundText] = useState('');
  const [privacyText, setPrivacyText] = useState('');
  const [eligibilityText, setEligibilityText] = useState('');

  // Pull static content from Airtable
  useEffect(() => {
    http
      .get(airtableURL + '/static-content')
      .then((res) => {
        setTosText(res.data['Terms of Service']);
        setRefundText(res.data['Refund Policy']);
        setPrivacyText(res.data['Privacy Statement']);
        setEligibilityText(res.data['Eligibility']);
      })
      .catch((error) => {
        console.log(error?.response?.data?.error);
      });
  }, []);

  return (
    <>
      <div className="d-flex justify-between align-center h-100 footer">
        <label className="footer__label">© {EVENT_NAME}, All Rights Reserved</label>
        <Row className="d-flex">
          <Col>
            <li>
              <a href="#" className="footer__label" onClick={handleTOSModal}>
                Terms of Service
              </a>
            </li>
          </Col>
          <Col>
            <li>
              <a href="#" className="footer__label" onClick={handleRefundModal}>
                Refund Policy
              </a>
            </li>
          </Col>
          <Col>
            <li>
              <a href="#" className="footer__label" onClick={handlePrivacyModal}>
                Privacy Statement
              </a>
            </li>
          </Col>
          <Col>
            <li>
              <a href="#" className="footer__label" onClick={handleEligibilityModal}>
                Eligibility
              </a>
            </li>
          </Col>
          <Col>
            <li>
              <a href="#" className="footer__label" onClick={handleHelpModal}>
                Need Help?
              </a>
            </li>
          </Col>
        </Row>
      </div>

      <Modal
        title="Terms of Service"
        open={isTOSModalOpen}
        onCancel={handleTOSModal}
        footer={[
          <Button type="primary" onClick={handleTOSModal}>
            Ok
          </Button>,
        ]}
      >
        {tosText}
      </Modal>

      <Modal
        title="Refund Policy"
        open={isRefundModalOpen}
        onCancel={handleRefundModal}
        footer={[
          <Button type="primary" onClick={handleRefundModal}>
            Ok
          </Button>,
        ]}
      >
        {refundText}
      </Modal>

      <Modal
        title="Privacy Statement"
        open={isPrivacyModalOpen}
        onCancel={handlePrivacyModal}
        footer={[
          <Button type="primary" onClick={handlePrivacyModal}>
            Ok
          </Button>,
        ]}
      >
        {privacyText}
      </Modal>

      <Modal
        title="Eligibility"
        open={isEligibilityModalOpen}
        onCancel={handleEligibilityModal}
        footer={[
          <Button type="primary" onClick={handleEligibilityModal}>
            Ok
          </Button>,
        ]}
      >
        {eligibilityText}
      </Modal>

      <HelpModal isHelpModalOpen={isHelpModalOpen} handleHelpModal={handleHelpModal} />
    </>
  );
};

export default CustomFooter;
