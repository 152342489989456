import { Link as RouterLink } from 'react-router-dom';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Divider, Checkbox } from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { UserContext, BreweryContext } from '../../context';
import { authURL, airtableURL } from '../../FetchURL';
import http from '../../http-common';

import ForgotPasswordModal from '../ForgotPassword';
import CustomButton from '../CustomButton';
import FormInput from '../FormInput';
import CustomAlert from '../Alert';

import EmailDomainModal from '../EmailDomainModal';
import httpCommon from '../../http-common';
import OTPModal from '../OTPModal';
import './index.scss';

const PROVIDERS = [
  'gmail.com',
  'outlook.com',
  'yahoo.com',
  'aol.com',
  'icloud.com',
  'protonmail.com',
  'zoho.com',
  'gmx.com',
  'mail.com',
  'tutanota.com',
];

const Login = () => {
  const navigate = useNavigate();

  const [user, setUser] = useContext(UserContext);
  const [brewery, setBrewery] = useContext(BreweryContext);
  const [errorMessage, setErrorMessage] = useState('');
  const [ModalOpen, setModalOpen] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [isEmailDomainModalOpen, setIsEmailDomainModalOpen] = useState(false);
  const [breweryAdminData, setBreweryAdminData] = useState(false);

  const [emailUser, setEmailUser] = useState(null);
  const [emailAdminUser, setEmailAdminUser] = useState(null);

  const handleEmailDomainModal = () => setIsEmailDomainModalOpen(!isEmailDomainModalOpen);

  const showPasswordModal = () => {
    setModalOpen(1);
  };

  const showOTPModal = () => {
    setModalOpen(2);
  };
  const handleOk = () => {
    setModalOpen(0);
  };

  const handleCancel = () => {
    setModalOpen(0);
  };

  const formik = useFormik({
    initialValues: {
      userEmail: '',
      userPassword: '',
    },
    onSubmit: async (values) => {
      formik.setSubmitting(false);
      setEmailAdminUser(null);
      setEmailUser(null);
      setBreweryAdminData(null);

      try {
        setIsLoading(true);

        const resUser = await http.post(authURL + '/login', {
          email: values.userEmail.toLowerCase(),
          password: values.userPassword,
        });

        const userData = await http.get(authURL + '/user');

        setUser({
          _id: resUser.data._id,
          email: resUser.data.email,
          fetched: true,
          role: userData.data.role,
          breweryId: userData.data.brewery[0],
        });

        await http.get(airtableURL + `/get-brewery-id/${userData.data.brewery[0]}`).then((res) => {
          setBrewery(res.data);

          http.post(airtableURL + `/logs`, {
            action: 'login success',
            user: values.userEmail,
          });

          let nextPath = sessionStorage.getItem('nextPath');

          if (nextPath) {
            sessionStorage.removeItem('nextPath');
            window.location.href = nextPath;
          } else {
            navigate('/', { replace: true });
          }
        });
      } catch (error) {
        console.log('error', error.response);

        if (error?.response?.data === 'awaiting_otp') {
          showOTPModal();
          return;
        }

        if (error?.response?.data === 'Incorrect email') {
          if (!checkEmail(values.userEmail, PROVIDERS)) {
            fetchEmailDomain(values.userEmail);
            return;
          }
        } else {
          http.post(airtableURL + `/logs`, {
            action: 'login fail',
            user: values.userEmail,
          });
        }
        setIsLoading(false);
        setErrorMessage(error?.response?.data || 'Authentication Failed.');
      }
    },
    validationSchema: Yup.object().shape({
      userEmail: Yup.string().email('Invalid email').required('Required'),
      userPassword: Yup.string().required('Required'),
    }),
  });

  function checkEmail(email, providers) {
    for (let i = 0; i < providers.length; i++) {
      if (email.includes(providers[i])) {
        return true;
      }
    }
    return false;
  }

  const fetchEmailDomain = async (email) => {
    try {
      const response = await httpCommon.get(airtableURL + '/get-user-domain/' + email);
      let adminUserEmail = response.data.fields.email;

      setBreweryAdminData(response.data);
      setEmailAdminUser(adminUserEmail);
      setEmailUser(email);

      handleEmailDomainModal();
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoading(false);
    }
  };

  const onRmemberClick = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  return (
    <div className="login d-flex justify-center align-center text-center">
      <div className="login__container">
        <img
          src="https://ontariobrewingawards.com/wp-content/uploads/2020/09/cropped-OBA_logo_FINAL-1-1.png"
          style={{ height: '165px' }}
          alt="logo"
        />
        <div className="login__container__topic">
          <label>Welcome to the Ontario Brewing Awards!</label>
        </div>
        <div className="login__container__description" style={{ marginTop: '20px' }}>
          <label>
            If you have an account, please log in below. Otherwise, click "Create an account" to set up your company.
          </label>
        </div>

        <Divider />
        <form onSubmit={formik.handleSubmit}>
          <FormInput
            id="userEmail"
            name="userEmail"
            onChange={formik.handleChange}
            value={formik.values.userEmail}
            placeHolder="User Email"
          />
          {formik.errors.userEmail && formik.touched.userEmail && (
            <div className="login__container__input-error">{formik.errors.userEmail}</div>
          )}
          <FormInput
            id="userPassword"
            name="userPassword"
            onChange={formik.handleChange}
            value={formik.values.userPassword}
            placeHolder="Password"
            password="password"
          />
          {formik.errors.userPassword && formik.touched.userPassword && (
            <div className="login__container__input-error">{formik.errors.userPassword}</div>
          )}
          <div className="text-left login__container__checkbox">
            <Checkbox onChange={onRmemberClick}>Remember me</Checkbox>
          </div>
          <CustomButton className="w-100" primary="primary" label="SIGN IN" htmlType="submit" loading={isLoading} />

          <div style={{ marginTop: '10px' }}></div>
          {/* <CustomButton
            className="w-100"
            style={{ marginTop: "10px" }}
            label="BUY EVENT TICKETS"
            onClick={() => {
              navigate("/ticket-quick-buy");
            }}
          /> */}
        </form>
        <Divider />
        <div className="d-flex justify-between login__container__link">
          <RouterLink to="/signup" className="login__container__link-items">
            Create an account
          </RouterLink>
          <label className="login__container__link-items" onClick={showPasswordModal}>
            Recover password
          </label>
        </div>
        {errorMessage !== '' && <CustomAlert type="error" message={errorMessage} onClose={setErrorMessage} />}
      </div>

      <ForgotPasswordModal isModalOpen={ModalOpen === 1} handleOk={handleOk} handleCancel={handleCancel} />

      <OTPModal
        isModalOpen={ModalOpen === 2}
        handleOk={handleOk}
        handleCancel={handleCancel}
        userEmail={formik.values.userEmail}
        userPassword={formik.values.userPassword}
      />

      {breweryAdminData && (
        <EmailDomainModal
          isEmailDomainModalOpen={isEmailDomainModalOpen}
          handleEmailDomainModal={handleEmailDomainModal}
          emailAdminUser={emailAdminUser}
          emailUser={emailUser}
          breweryAdminData={breweryAdminData}
        />
      )}
    </div>
  );
};

export default Login;
