import FormInput from "../../FormInput";

import "../index.scss";

const Step1 = (props) => {
  return (
    <>
      <FormInput
        id="userEmail"
        name="userEmail"
        onChange={props.formik.handleChange}
        value={props.formik.values.userEmail}
        placeHolder="User Email"
      />
      {props.formik.errors.userEmail && props.formik.touched.userEmail && (
        <div className="custom__input-error">
          {props.formik.errors.userEmail}
        </div>
      )}
      <FormInput
        id="userPassword"
        name="userPassword"
        onChange={props.formik.handleChange}
        value={props.formik.values.userPassword}
        placeHolder="Password"
        password="password"
      />
      {props.formik.errors.userPassword &&
        props.formik.touched.userPassword && (
          <div className="custom__input-error">
            {props.formik.errors.userPassword}
          </div>
        )}
      <FormInput
        id="confirmPassword"
        name="confirmPassword"
        onChange={props.formik.handleChange}
        value={props.formik.values.confirmPassword}
        placeHolder="Confirm Password"
        password="password"
      />
      {props.formik.errors.confirmPassword &&
        props.formik.touched.confirmPassword && (
          <div className="custom__input-error">
            {props.formik.errors.confirmPassword}
          </div>
        )}
    </>
  );
};

export default Step1;
