import { Space, Row, Col, Checkbox, Slider, Typography, Divider } from 'antd';
import { useSelector } from 'react-redux';
import TextArea from 'antd/es/input/TextArea';

import { LIMIT_SCORE } from '../../consts/LIMIT_SCORE';
import BlockSlider from '../JudgingFormComponents/BlockSlider';
import ScoringGuide from './ScoringGuide';
import Paragraph from 'antd/es/typography/Paragraph';

export default function Flavor({ disabled }) {
  const flavorStore = useSelector((state) => state.judgingForm.flavor);

  return (
    <>
      <div className="form__section tastingnote__section">
        <Row className="form__section_title">
          <Col xs={12}>
            <h1>Flavour</h1>
          </Col>

          <Col xs={12} style={{ marginBottom: 2 }}>
            <Space align="end" direction="vertical" size={1} className="w-full">
              <Row>
                <input disabled={disabled} className="score" value={flavorStore.score.value} type="text" />
                <Space align="end">
                  <p className="text-gray" style={{ marginLeft: 4, textAlign: 'center' }}>
                    Out of {LIMIT_SCORE.flavor}
                  </p>
                </Space>
              </Row>
            </Space>
          </Col>
        </Row>

        <Row style={{ marginTop: 15 }}>
          <BlockSlider title="Flavour score" score={flavorStore.score.value}>
            <Slider
              disabled={disabled}
              value={flavorStore.score.value}
              defaultValue={0}
              style={{ marginTop: 18 }}
              min={0}
              max={LIMIT_SCORE.flavor}
            />
          </BlockSlider>
        </Row>

        <Divider style={{ marginTop: 20, marginBottom: 40 }} />

        <ScoringGuide />

        <div>
          <Row className="section-margin-y">
            <Col xs={24} md={12} lg={8} style={{ marginBottom: 10 }}>
              <Space>
                <h3 className="title">Malt</h3>
              </Space>

              <div className="form__section__slider w-full">
                <Row gutter={1}>
                  <Col xs={18}>
                    <Slider
                      disabled={disabled}
                      defaultValue={0}
                      style={{ marginTop: 18 }}
                      min={0}
                      max={5}
                      value={flavorStore.malt.value}
                    />
                  </Col>
                  <Col xs={6}>
                    <Space align="end">
                      <Space className="form__section__slider_item">
                        <p>{flavorStore.malt.value}</p>
                      </Space>
                    </Space>
                  </Col>
                </Row>
              </div>

              <Row>
                <Col span={24}>
                  <Checkbox disabled={disabled} checked={flavorStore.malt.grainy} value="Grainy">
                    Grainy
                  </Checkbox>
                  <Checkbox disabled={disabled} checked={flavorStore.malt.rich} value="Rich">
                    Rich
                  </Checkbox>
                  <Checkbox disabled={disabled} checked={flavorStore.malt.roasty} value="Roasty">
                    Roasty
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox disabled={disabled} checked={flavorStore.malt.caramel} value="Caramel">
                    Caramel
                  </Checkbox>
                  <Checkbox disabled={disabled} checked={flavorStore.malt.darkFruit} value="Dark Fruit">
                    Dark fruit
                  </Checkbox>
                  <Checkbox disabled={disabled} checked={flavorStore.malt.burnt} value="Burnt">
                    Burnt
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox disabled={disabled} checked={flavorStore.malt.bready} value="Bready">
                    Bready
                  </Checkbox>
                  <Checkbox disabled={disabled} checked={flavorStore.malt.toasty} value="Toasty">
                    Toasty
                  </Checkbox>
                  <Checkbox disabled={disabled} checked={flavorStore.malt.chocolate} value="Chocolate">
                    Chocolate
                  </Checkbox>
                </Col>
              </Row>
            </Col>

            <Col xs={24} md={12} lg={8} style={{ marginBottom: 10 }}>
              <Space>
                <h3 className="title">Hops</h3>
              </Space>

              <div className="form__section__slider w-full">
                <Row gutter={1}>
                  <Col xs={18}>
                    <Slider
                      disabled={disabled}
                      value={flavorStore.hops.value}
                      defaultValue={0}
                      style={{ marginTop: 18 }}
                      min={0}
                      max={5}
                    />
                  </Col>
                  <Col xs={6}>
                    <Space align="end">
                      <Space className="form__section__slider_item">
                        <p>{flavorStore.hops.value}</p>
                      </Space>
                    </Space>
                  </Col>
                </Row>
              </div>

              <Row>
                <Col span={24}>
                  <Checkbox disabled={disabled} checked={flavorStore.hops.citrusy} value="Citrusy">
                    Citrusy
                  </Checkbox>
                  <Checkbox disabled={disabled} checked={flavorStore.hops.grassy} value="Grassy">
                    Grassy
                  </Checkbox>
                  <Checkbox disabled={disabled} checked={flavorStore.hops.spicy} value="Spicy">
                    Spicy
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox disabled={disabled} checked={flavorStore.hops.earthy} value="Earthy">
                    Earthy
                  </Checkbox>
                  <Checkbox disabled={disabled} checked={flavorStore.hops.herbal} value="Herbal">
                    Herbal
                  </Checkbox>
                  <Checkbox disabled={disabled} checked={flavorStore.hops.woody} value="Woody">
                    Woody
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox disabled={disabled} checked={flavorStore.hops.floral} value="Floral">
                    Floral
                  </Checkbox>
                  <Checkbox disabled={disabled} checked={flavorStore.hops.piney} value="Piney">
                    Piney
                  </Checkbox>
                  <Checkbox disabled={disabled} checked={flavorStore.hops.tropical} value="Tropical">
                    Tropical
                  </Checkbox>
                </Col>
              </Row>
            </Col>

            <Col xs={24} md={12} lg={8} style={{ marginBottom: 10 }}>
              <Space>
                <h3 className="title">Esters</h3>
              </Space>

              <div className="form__section__slider w-full">
                <Row gutter={1}>
                  <Col xs={18}>
                    <Slider
                      disabled={disabled}
                      value={flavorStore.esters.value}
                      defaultValue={0}
                      style={{ marginTop: 18 }}
                      min={0}
                      max={5}
                    />
                  </Col>
                  <Col xs={6}>
                    <Space align="end">
                      <Space className="form__section__slider_item">
                        <p>{flavorStore.esters.value}</p>
                      </Space>
                    </Space>
                  </Col>
                </Row>
              </div>

              <Row>
                <Col span={24}>
                  <Checkbox disabled={disabled} checked={flavorStore.esters.stoneFruit} value="Stone Fruit">
                    Stone Fruit
                  </Checkbox>
                  <Checkbox disabled={disabled} checked={flavorStore.esters.banana} value="Banana">
                    Banana
                  </Checkbox>
                  <Checkbox disabled={disabled} checked={flavorStore.esters.driedFruit} value="Dried Fruit">
                    Dried Fruit
                  </Checkbox>
                </Col>

                <Col span={24}>
                  <Checkbox disabled={disabled} checked={flavorStore.esters.fruity} value="Fruity">
                    Fruity
                  </Checkbox>
                  <Checkbox disabled={disabled} checked={flavorStore.esters.berry} value="Berry">
                    Berry
                  </Checkbox>
                  <Checkbox disabled={disabled} checked={flavorStore.esters.grape} value="Grape">
                    Grape
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox disabled={disabled} checked={flavorStore.esters.apple} value="Apple">
                    Apple
                  </Checkbox>
                  <Checkbox disabled={disabled} checked={flavorStore.esters.pear} value="Pear">
                    Pear
                  </Checkbox>
                  <Checkbox disabled={disabled} checked={flavorStore.esters.citrus} value="Citrus">
                    Citrus
                  </Checkbox>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="section-margin-y">
            <BlockSlider title="Phenols" score={flavorStore.phenols.value}>
              <Slider
                disabled={disabled}
                value={flavorStore.phenols.value}
                defaultValue={0}
                style={{ marginTop: 18 }}
                min={0}
                max={5}
              />
            </BlockSlider>

            <BlockSlider title="Alcohol" score={flavorStore.alcohol.value}>
              <Slider
                disabled={disabled}
                value={flavorStore.alcohol.value}
                defaultValue={0}
                style={{ marginTop: 18 }}
                min={0}
                max={5}
              />
            </BlockSlider>

            <BlockSlider title="Sweetness" score={flavorStore.sweetness.value}>
              <Slider
                disabled={disabled}
                value={flavorStore.sweetness.value}
                defaultValue={0}
                style={{ marginTop: 18 }}
                min={0}
                max={5}
              />
            </BlockSlider>
          </Row>

          <Row className="section-margin-y">
            {flavorStore?.bitterness && (
              <BlockSlider title="Bitterness" score={flavorStore.bitterness.value}>
                <Slider
                  disabled={disabled}
                  value={flavorStore.bitterness.value}
                  defaultValue={0}
                  style={{ marginTop: 18 }}
                  min={0}
                  max={5}
                />
              </BlockSlider>
            )}

            <BlockSlider title="Acidity" score={flavorStore.acidity.value}>
              <Slider
                disabled={disabled}
                value={flavorStore.acidity.value}
                defaultValue={0}
                style={{ marginTop: 18 }}
                min={0}
                max={5}
              />
            </BlockSlider>

            <Col xs={24} md={16} lg={16} style={{ marginBottom: 10 }} className="flex-column">
              <Space style={{ marginBottom: 5 }}>
                <h3 className="title">Other</h3>
              </Space>

              <Row>
                <Col span={24}>
                  <Checkbox disabled={disabled} checked={flavorStore.comments.brett} value="Brett">
                    Brett
                  </Checkbox>
                  <Checkbox disabled={disabled} checked={flavorStore.comments.fruit} value="Fruit">
                    Fruit
                  </Checkbox>
                  <Checkbox disabled={disabled} checked={flavorStore.comments.lactic} value="Lactic">
                    Lactic
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox disabled={disabled} checked={flavorStore.comments.smoke} value="Smoke">
                    Smoke
                  </Checkbox>
                  <Checkbox disabled={disabled} checked={flavorStore.comments.spice} value="Spice">
                    Spice
                  </Checkbox>
                  <Checkbox disabled={disabled} checked={flavorStore.comments.vinous} value="Vinous">
                    Vinous
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox disabled={disabled} checked={flavorStore.comments.wood} value="Wood">
                    Wood
                  </Checkbox>
                </Col>
              </Row>
            </Col>

            {flavorStore.balance && (
              <>
                <Col span={24} style={{ marginTop: 8 }}>
                  <Space className="margin-x" style={{ marginLeft: 0 }}>
                    <Typography>
                      <Paragraph strong>Balance Scoring Guide:</Paragraph>
                    </Typography>
                  </Space>
                  <Space className="margin-x">
                    <Typography>
                      <Paragraph>0 - N/A</Paragraph>
                    </Typography>
                  </Space>
                  <Space className="margin-x">
                    <Typography>
                      <Paragraph>1 - Malty</Paragraph>
                    </Typography>
                  </Space>

                  <Space className="margin-x">
                    <Typography>
                      <Paragraph>5 - Hoppy</Paragraph>
                    </Typography>
                  </Space>
                </Col>

                <BlockSlider title="Balance" score={flavorStore.balance.value}>
                  <Slider
                    disabled={disabled}
                    value={flavorStore.balance.value}
                    defaultValue={0}
                    style={{ marginTop: 18 }}
                    min={0}
                    max={5}
                  />
                </BlockSlider>
              </>
            )}
          </Row>
        </div>

        <div>
          <Row className="section-margin-y">
            <Col xs={24} lg={8}>
              <Space style={{ marginBottom: 16 }}>
                <h3 className="title">Comments</h3>
              </Space>

              <TextArea
                disabled={disabled}
                style={{ marginBottom: 8 }}
                className="w-full"
                rows={4}
                value={flavorStore.comments.value}
              />
            </Col>
          </Row>
        </div>

        <Row className="form__section_subtitle">
          <Col xs={24}>
            <h1>Flavour Flaws</h1>
          </Col>
        </Row>
        <ScoringGuide flaws />
        <div>
          <Row className="section-margin-y">
            <BlockSlider title="Acetaldehyde" score={flavorStore.acetaldehyde.value}>
              <Slider
                disabled={disabled}
                value={flavorStore.acetaldehyde.value}
                defaultValue={0}
                style={{ marginTop: 18 }}
                min={0}
                max={3}
              />
            </BlockSlider>

            <BlockSlider title="Alcoholic/Hot" score={flavorStore.alcoholicHot.value}>
              <Slider
                disabled={disabled}
                value={flavorStore.alcoholicHot.value}
                defaultValue={0}
                style={{ marginTop: 18 }}
                min={0}
                max={3}
              />
            </BlockSlider>

            <BlockSlider title="Astringent" score={flavorStore.astringent.value}>
              <Slider
                disabled={disabled}
                value={flavorStore.astringent.value}
                defaultValue={0}
                style={{ marginTop: 18 }}
                min={0}
                max={3}
              />
            </BlockSlider>
          </Row>

          <Row className="section-margin-y">
            <BlockSlider title="Diacetyl" score={flavorStore.diacetyl.value}>
              <Slider
                disabled={disabled}
                value={flavorStore.diacetyl.value}
                defaultValue={0}
                style={{ marginTop: 18 }}
                min={0}
                max={3}
              />
            </BlockSlider>

            <BlockSlider title="DMS" score={flavorStore.dms.value}>
              <Slider
                disabled={disabled}
                value={flavorStore.dms.value}
                defaultValue={0}
                style={{ marginTop: 18 }}
                min={0}
                max={3}
              />
            </BlockSlider>

            <BlockSlider title="Estery" score={flavorStore.estery.value}>
              <Slider
                disabled={disabled}
                value={flavorStore.estery.value}
                defaultValue={0}
                style={{ marginTop: 18 }}
                min={0}
                max={3}
              />
            </BlockSlider>
          </Row>

          <Row className="section-margin-y">
            <BlockSlider title="Grassy" score={flavorStore.grassy.value}>
              <Slider
                disabled={disabled}
                value={flavorStore.grassy.value}
                defaultValue={0}
                style={{ marginTop: 18 }}
                min={0}
                max={3}
              />
            </BlockSlider>

            <BlockSlider title="Light-struck" score={flavorStore.lightStruck.value}>
              <Slider
                disabled={disabled}
                value={flavorStore.lightStruck.value}
                defaultValue={0}
                style={{ marginTop: 18 }}
                min={0}
                max={3}
              />
            </BlockSlider>

            <BlockSlider title="Medicinal" score={flavorStore.medicinal.value}>
              <Slider
                disabled={disabled}
                value={flavorStore.medicinal.value}
                defaultValue={0}
                style={{ marginTop: 18 }}
                min={0}
                max={3}
              />
            </BlockSlider>
          </Row>

          <Row className="section-margin-y">
            <BlockSlider title="Metallic" score={flavorStore.metallic.value}>
              <Slider
                disabled={disabled}
                value={flavorStore.metallic.value}
                defaultValue={0}
                style={{ marginTop: 18 }}
                min={0}
                max={3}
              />
            </BlockSlider>

            <BlockSlider title="Musty" score={flavorStore.musty.value}>
              <Slider
                disabled={disabled}
                value={flavorStore.musty.value}
                defaultValue={0}
                style={{ marginTop: 18 }}
                min={0}
                max={3}
              />
            </BlockSlider>

            <BlockSlider title="Oxidized" score={flavorStore.oxidized.value}>
              <Slider
                disabled={disabled}
                value={flavorStore.oxidized.value}
                defaultValue={0}
                style={{ marginTop: 18 }}
                min={0}
                max={3}
              />
            </BlockSlider>
          </Row>

          <Row className="section-margin-y">
            <BlockSlider title="Plastic" score={flavorStore.plastic.value}>
              <Slider
                disabled={disabled}
                value={flavorStore.plastic.value}
                defaultValue={0}
                style={{ marginTop: 18 }}
                min={0}
                max={3}
              />
            </BlockSlider>

            <BlockSlider title="Solvent/Fusel" score={flavorStore.solventFusel.value}>
              <Slider
                disabled={disabled}
                value={flavorStore.solventFusel.value}
                defaultValue={0}
                style={{ marginTop: 18 }}
                min={0}
                max={3}
              />
            </BlockSlider>

            <BlockSlider title="Sour/Acidic" score={flavorStore.sourAcidic.value}>
              <Slider
                disabled={disabled}
                value={flavorStore.sourAcidic.value}
                defaultValue={0}
                style={{ marginTop: 18 }}
                min={0}
                max={3}
              />
            </BlockSlider>
          </Row>

          <Row className="section-margin-y">
            <BlockSlider title="Smoky" score={flavorStore.smoky.value}>
              <Slider
                disabled={disabled}
                value={flavorStore.smoky.value}
                defaultValue={0}
                style={{ marginTop: 18 }}
                min={0}
                max={3}
              />
            </BlockSlider>

            <BlockSlider title="Spicy" score={flavorStore.spicy.value}>
              <Slider
                disabled={disabled}
                value={flavorStore.spicy.value}
                defaultValue={0}
                style={{ marginTop: 18 }}
                min={0}
                max={3}
              />
            </BlockSlider>

            <BlockSlider title="Sulfur" score={flavorStore.sulfur.value}>
              <Slider
                disabled={disabled}
                value={flavorStore.sulfur.value}
                defaultValue={0}
                style={{ marginTop: 18 }}
                min={0}
                max={3}
              />
            </BlockSlider>
          </Row>

          <Row className="section-margin-y">
            <BlockSlider title="Vegetal" score={flavorStore.vegetal.value}>
              <Slider
                disabled={disabled}
                value={flavorStore.vegetal.value}
                defaultValue={0}
                style={{ marginTop: 18 }}
                min={0}
                max={3}
              />
            </BlockSlider>

            <BlockSlider title="Vinegary" score={flavorStore.vinegary.value}>
              <Slider
                disabled={disabled}
                defaultValue={0}
                value={flavorStore.vinegary.value}
                style={{ marginTop: 18 }}
                min={0}
                max={3}
              />
            </BlockSlider>
          </Row>
        </div>
      </div>
    </>
  );
}
