import { useState } from "react";
import { Input, Upload, Button, Checkbox } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import cloudinaryUpload from "../../../services/uploads";
import Loading from "../../Loading";
import { PrivacySettingsModal, EligibleCriteriasModal } from "../../Modal";
import "../index.scss";

const { TextArea } = Input;
const Step3 = (props) => {
  const [loading, setLoading] = useState(false);
  const [pictureURL, setPictureURL] = useState("");
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
  const [isEligibleModalOpen, setIsEligibleModalOpen] = useState(false);

  const showPrivacyModal = () => {
    setIsPrivacyModalOpen(true);
  };

  const handlePrivacyOk = () => {
    setIsPrivacyModalOpen(false);
  };

  const handlePrivacyCancel = () => {
    setIsPrivacyModalOpen(false);
  };

  const showEligibleModal = () => {
    setIsEligibleModalOpen(true);
  };

  const handleEligibleOk = () => {
    setIsEligibleModalOpen(false);
  };

  const handleEligibleCancel = () => {
    setIsEligibleModalOpen(false);
  };

  const handleUpload = async (e) => {
    const uploadData = new FormData();
    uploadData.append("file", e, "file");
    setLoading(true);
    const res = await cloudinaryUpload(uploadData);
    setLoading(false);
    props.formik.values.companyLogo = res.secure_url;
    setPictureURL(res.secure_url);
  };

  return (
    <div className="text-left form-step3">
      <div className="upload-button">
        {loading ? (
          <Loading />
        ) : (
          <div className="d-flex">
            <Upload
              name="logo"
              action={(e) => handleUpload(e)}
              listType="picture"
              maxCount={1}
            >
              <Button icon={<UploadOutlined />}>Upload Company Logo</Button>
            </Upload>
            {pictureURL !== "" && (
              <img
                src={pictureURL}
                style={{
                  width: "50px",
                  height: "50px",
                  marginLeft: "50px",
                  marginTop: "3px",
                }}
                alt="logopicture"
              />
            )}
          </div>
        )}
      </div>
      <TextArea
        name="companyBio"
        id="companyBio"
        onChange={props.formik.handleChange}
        value={props.formik.values.companyBio}
        rows={6}
        className="text-area"
        placeholder="Company Short Bio & Additional Information (Optional)"
      />
      <label className="form-step3__label">
        Information entered here will be used by Taps Media Inc. to promote
        brewery award winners
      </label>
      <div>
        <Checkbox
          className="form-step3__check"
          name="termsOfServices"
          id="termsOfServices"
          onChange={props.formik.handleChange}
          checked={props.formik.values.termsOfServices}
        >
          I accept the{" "}
          <label onClick={showPrivacyModal} className="form-step3__modal-label">
            Terms of Service Agreement
          </label>
        </Checkbox>
        {props.formik.errors.termsOfServices &&
          props.formik.touched.termsOfServices && (
            <div className="custom__input-error">
              {props.formik.errors.termsOfServices}
            </div>
          )}
      </div>
      <div>
        <Checkbox
          name="eligibilityCriteria"
          id="eligibilityCriteria"
          onChange={props.formik.handleChange}
          checked={props.formik.values.eligibilityCriteria}
          className="form-step3__check"
        >
          I understand the{" "}
          <label
            onClick={showEligibleModal}
            className="form-step3__modal-label"
          >
            Eligibility Criteria
          </label>
        </Checkbox>
        {props.formik.errors.eligibilityCriteria &&
          props.formik.touched.eligibilityCriteria && (
            <div className="custom__input-error">
              {props.formik.errors.eligibilityCriteria}
            </div>
          )}
      </div>
      <PrivacySettingsModal
        isPrivacyModalOpen={isPrivacyModalOpen}
        handlePrivacyOk={handlePrivacyOk}
        handlePrivacyCancel={handlePrivacyCancel}
      />
      <EligibleCriteriasModal
        isEligibleModalOpen={isEligibleModalOpen}
        handleEligibleOk={handleEligibleOk}
        handleEligibleCancel={handleEligibleCancel}
      />
    </div>
  );
};

export default Step3;
