import { useState, useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Table, Row } from 'antd';

import RequestInvitationModal from '../RequestInvitationModal';
import RemoveUserModal from '../RemoveUserModal';
import { airtableURL } from '../../FetchURL';
import { UserContext } from '../../context';
import CustomButton from '../CustomButton';
import AddUserModal from '../AddUserModal';
import http from '../../http-common';
import Loading from '../Loading';
import './index.scss';

const UsersPage = () => {
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [isRemoveUserModalOpen, setIsRemoveUserModalOpen] = useState(false);
  const [userSelectedData, setUserSelectedData] = useState(null);
  const [user] = useContext(UserContext);

  const [query] = useSearchParams();
  const requestInvitationEmail = query.get('requestInvitation');

  const [isRequestInvitationModalOpen, setIsRequestInvitationModalOpen] = useState(false);

  const handleAddUserModal = () => setIsAddUserModalOpen(!isAddUserModalOpen);
  const handleRemoveUserModal = () => setIsRemoveUserModalOpen(!isRemoveUserModalOpen);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRequestInvitationModal = () => setIsRequestInvitationModalOpen(!isRequestInvitationModalOpen);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const refetchUser = async () => {
    try {
      const res = await http.get(airtableURL + `/get-users-brewery/${user.breweryId}`);
      setData(res.data);
    } catch (error) {
      console.error('There was an error fetch auth', error);
      return;
    }
  };

  useEffect(() => {
    if (user?.breweryId) {
      const fetchUsersData = async () => {
        try {
          setLoading(true);

          const res = await http.get(airtableURL + `/get-users-brewery/${user.breweryId}`);

          if (res.status !== 200) return setLoading(false);
          setData(res.data);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.error('There was an error fetch auth', error);
          return;
        }
      };

      fetchUsersData();
    }
  }, [user.breweryId]);

  const findEmail = (data, email) => {
    if (!data) return false;
    return data.some((item) => item.email.toLowerCase() === email.toLowerCase());
  };

  useEffect(() => {
    if (requestInvitationEmail && data.length > 0) {
      if (!findEmail(data, requestInvitationEmail)) {
        handleRequestInvitationModal();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestInvitationEmail, loading]);

  const columns = [
    {
      title: 'email',
      dataIndex: 'email',
      key: 'email',
      render: (text) => {
        return <span>{text}</span>;
      },
    },
    {
      title: 'role',
      dataIndex: 'role',
      key: 'role',
      sorter: (a, b) => a.role.localeCompare(b.role),
      defaultSortOrder: 'ascend',
    },
    {
      title: '',
      key: '',
      render: (_, record) => {
        if (record.role !== 'Admin')
          return (
            <Row justify="end">
              <button
                className="checkout-button"
                onClick={() => {
                  setUserSelectedData(record);
                  handleRemoveUserModal();
                }}
              >
                Remove
              </button>
            </Row>
          );
      },
    },
  ];

  const columnsUser = [
    {
      title: 'email',
      dataIndex: 'email',
      key: 'email',
      render: (text) => {
        return <span>{text}</span>;
      },
    },
    {
      title: 'role',
      dataIndex: 'role',
      key: 'role',
      sorter: (a, b) => a.role.localeCompare(b.role),
      defaultSortOrder: 'ascend',
    },
  ];

  return (
    <>
      <div className="custom-container">
        <div className="custom-label__header d-flex justify-between">
          <label>Users</label>

          {user.role === 'Admin' && !loading && (
            <div className="d-flex">
              <CustomButton label="+ Add User" onClick={handleAddUserModal} />
              <div style={{ marginLeft: '20px' }} />
            </div>
          )}
        </div>

        <div className="users">
          {loading ? (
            <Loading />
          ) : (
            <Table className="users-table" columns={user.role === 'Admin' ? columns : columnsUser} dataSource={data} />
          )}
        </div>
      </div>

      <AddUserModal
        handleAddUserModal={handleAddUserModal}
        isAddUserModalOpen={isAddUserModalOpen}
        refetchUserData={refetchUser}
      />

      {requestInvitationEmail && (
        <RequestInvitationModal
          isRequestInvitationModalOpen={isRequestInvitationModalOpen}
          handleRequestInvitationModal={handleRequestInvitationModal}
          requestInvitationEmail={requestInvitationEmail}
          refetchUserData={refetchUser}
        />
      )}

      {userSelectedData && (
        <RemoveUserModal
          handleRemoveUserModal={handleRemoveUserModal}
          isRemoveUserModalOpen={isRemoveUserModalOpen}
          userSelectedData={userSelectedData}
          refetchUserData={refetchUser}
        />
      )}
    </>
  );
};

export default UsersPage;
