import { Button } from 'antd';
import './index.scss';

const CustomButton = (props) => {
  return (
    <>
      {props.primary === 'primary' ? (
        <Button
          type={props.primary}
          onClick={props.onClick}
          className={`custom-button ${props.className}`}
          loading={props.loading ? props.loading : false}
          disabled={props?.disabled || false}
          htmlType={props.htmlType}
          style={props.style}
        >
          {props.label}
        </Button>
      ) : props.svg ? (
        <Button
          onClick={props.onClick}
          className={`svg-button ${props.className}`}
          loading={props.loading ? props.loading : false}
          htmlType={props.htmlType}
          disabled={props?.disabled || false}
          style={props.style}
        >
          <label className="d-flex align-center">
            <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14.4208 8.96719H5.11616L5.58335 8.01562L13.3458 8.00156C13.6083 8.00156 13.8333 7.81406 13.8802 7.55469L14.9552 1.5375C14.9833 1.37969 14.9412 1.21719 14.838 1.09375C14.7871 1.033 14.7235 0.984065 14.6517 0.950339C14.58 0.916613 14.5017 0.898903 14.4224 0.898438L4.54741 0.865625L4.46304 0.46875C4.40991 0.215625 4.18179 0.03125 3.92241 0.03125H1.50835C1.36207 0.03125 1.22177 0.0893608 1.11834 0.192799C1.0149 0.296237 0.956787 0.436529 0.956787 0.582813C0.956787 0.729096 1.0149 0.869388 1.11834 0.972826C1.22177 1.07626 1.36207 1.13438 1.50835 1.13438H3.47554L3.84429 2.8875L4.7521 7.28281L3.58335 9.19063C3.52265 9.27254 3.4861 9.36981 3.47781 9.47143C3.46953 9.57305 3.48985 9.67496 3.53647 9.76562C3.63022 9.95156 3.81929 10.0688 4.02866 10.0688H5.00991C4.80072 10.3466 4.68773 10.685 4.68804 11.0328C4.68804 11.9172 5.40679 12.6359 6.29116 12.6359C7.17554 12.6359 7.89429 11.9172 7.89429 11.0328C7.89429 10.6844 7.77866 10.3453 7.57241 10.0688H10.0896C9.88041 10.3466 9.76742 10.685 9.76772 11.0328C9.76772 11.9172 10.4865 12.6359 11.3708 12.6359C12.2552 12.6359 12.974 11.9172 12.974 11.0328C12.974 10.6844 12.8583 10.3453 12.6521 10.0688H14.4224C14.7255 10.0688 14.974 9.82187 14.974 9.51719C14.9731 9.37106 14.9144 9.23122 14.8108 9.12818C14.7072 9.02514 14.567 8.96727 14.4208 8.96719ZM4.7771 1.95312L13.774 1.98281L12.8927 6.91719L5.82554 6.92969L4.7771 1.95312ZM6.29116 11.5266C6.01929 11.5266 5.79741 11.3047 5.79741 11.0328C5.79741 10.7609 6.01929 10.5391 6.29116 10.5391C6.56304 10.5391 6.78491 10.7609 6.78491 11.0328C6.78491 11.1638 6.73289 11.2894 6.6403 11.3819C6.5477 11.4745 6.42211 11.5266 6.29116 11.5266ZM11.3708 11.5266C11.099 11.5266 10.8771 11.3047 10.8771 11.0328C10.8771 10.7609 11.099 10.5391 11.3708 10.5391C11.6427 10.5391 11.8646 10.7609 11.8646 11.0328C11.8646 11.1638 11.8126 11.2894 11.72 11.3819C11.6274 11.4745 11.5018 11.5266 11.3708 11.5266Z"
                fill="#1890FF"
              />
            </svg>
            <div style={{ marginRight: '6px' }}></div>
            {props.label}
          </label>
        </Button>
      ) : (
        <Button
          onClick={props.onClick}
          className={`default-button ${props.className}`}
          loading={props.loading ? props.loading : false}
          htmlType={props.htmlType}
          disabled={props?.disabled || false}
        >
          {props.label}
        </Button>
      )}
    </>
  );
};

export default CustomButton;
