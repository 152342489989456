import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Alert } from 'antd';

import { airtableURL } from '../../FetchURL';
import http from '../../http-common';
import './index.scss';
import RegistrationWidget from '../RegistrationWidget';

const TicketRegistrationPage = () => {
  const { eventId } = useParams();
  const [eventData, setEventData] = useState({});
  const [totalTicketsBought, setTotalTicketsBought] = useState(0);
  const [remainingTickets, setRemainingTickets] = useState(120);
  const [visible, setVisible] = useState(true);

  // Use the event ID to get the event data
  useEffect(() => {
    if (eventId) {
      http.get(airtableURL + `/events/${eventId}`).then((res) => {
        setEventData(res.data);
      });

      http.get(airtableURL + `/tickets`).then((res) => {
        setTotalTicketsBought(res.data.length);
      });
    }
    // Fetch the event data
  }, [eventId]);

  useEffect(() => {
    setRemainingTickets(120 - totalTicketsBought);
  }, [totalTicketsBought]);

  return (
    <div className="custom-container">
      {remainingTickets < 15 ? (
        <Alert
          message="Limited ticket stock available!"
          description={`There are a limited number of tickets! They will be sold on a first come first serve basis. The remaining number of tickets is: ${remainingTickets}`}
          type="error"
        />
      ) : null}
      <div className="custom-label__header">
        <label>{eventData?.fields?.['Event Name']} Ticket Registration</label>
      </div>
      <div className="content">
        <RegistrationWidget remainingTickets={remainingTickets} eventData={eventData} />
      </div>
    </div>
  );
};

export default TicketRegistrationPage;
