import { Button, Form, Modal, Input, message } from 'antd';
import { useContext, useState } from 'react';

import { authURL, emailURL, airtableURL } from '../../FetchURL';
import { BreweryContext, UserContext } from '../../context';
import httpCommon from '../../http-common';

const ROL = ['User'];

export default function AddUserModal({ isAddUserModalOpen, handleAddUserModal, refetchUserData }) {
  const [brewery] = useContext(BreweryContext);
  const [user] = useContext(UserContext);

  const [messageApi, contextHolder] = message.useMessage();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = Form.useForm();

  const handleFinish = async (values) => {
    try {
      setIsSubmitting(true);

      const response = await httpCommon.post(authURL + '/create-user-from-brewery', {
        email: values.email,
        role: 'User',
        breweryId: user.breweryId,
      });

      await httpCommon.post(emailURL + '/new-user', {
        email: values.email,
        breweryName: brewery.fields['Brewery Name'],
        password: response.data.password,
      });

      messageApi.success('User added!');
      refetchUserData();

      form.resetFields();
      handleAddUserModal();

      httpCommon.post(airtableURL + `/logs`, {
        action: 'user added',
        user: values.email,
      });
    } catch (e) {
      console.log(e);
      messageApi.error('Error request. Please, check your connection and try again!');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Modal
        open={isAddUserModalOpen}
        onCancel={handleAddUserModal}
        title="Add User"
        footer={[
          <Button disabled={isSubmitting} loading={isSubmitting} onClick={() => form.submit()}>
            Add
          </Button>,
        ]}
      >
        <Form form={form} onFinish={handleFinish}>
          <Form.Item name="email" rules={[{ required: true }, { type: 'email', message: 'Enter a valid email' }]}>
            <Input type="email" disabled={isSubmitting} placeholder="Email" />
          </Form.Item>

          {/*
            <Form.Item name="role" rules={[{ required: true }]}>
              <Select
                disabled={isSubmitting}
                placeholder="Role"
                options={ROL.map((item) => ({ label: item, value: item }))}
              />
            </Form.Item>
          */}
        </Form>
      </Modal>

      {contextHolder}
    </>
  );
}
