import React, { useContext, useState } from 'react';
import { UserContext, BreweryContext } from '../../context';
import { Modal, message } from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Loading from '../Loading';
import FormInput from '../FormInput';
import CustomButton from '../CustomButton';
import { useNavigate } from 'react-router-dom';

import http from '../../http-common';
import { authURL, airtableURL } from '../../FetchURL';

import './index.scss';

const OTPModal = (props) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [user, setUser] = useContext(UserContext);
  const [brewery, setBrewery] = useContext(BreweryContext);

  const formik = useFormik({
    initialValues: {
      otp: '',
    },
    onSubmit: async (values) => {
      setLoading(true);
      formik.setSubmitting(false);
      try {
        const resUser = await http.post(authURL + '/login', {
          email: props.userEmail,
          password: props.userPassword,
          otp: values.otp,
        });

        const userData = await http.get(authURL + '/user');

        setUser({
          _id: resUser.data._id,
          email: resUser.data.email,
          fetched: true,
          role: userData.data.role,
          breweryId: userData.data.brewery[0],
        });

        await http.get(airtableURL + `/get-brewery/${props.userEmail}`).then((res) => {
          setBrewery(res.data);
          navigate('/', { replace: true });
        });
      } catch (error) {
        setLoading(false);
        message.error(error?.response?.data || 'Failed');
      }
    },
    validationSchema: Yup.object().shape({
      otp: Yup.string().required('Required'),
    }),
  });

  return (
    <>
      <Modal
        title="OTP Verification"
        open={props.isModalOpen}
        onOk={props.handleOk}
        onCancel={props.handleCancel}
        className="modal"
        footer={[
          <div key="OTP"></div>,
          <CustomButton
            primary="primary"
            onClick={formik.handleSubmit}
            loading={loading}
            label="Submit"
            key="submit"
          />,
        ]}
      >
        {loading ? (
          <div style={{ height: '300px' }}>
            <Loading />
          </div>
        ) : (
          <>
            <div style={{ marginBottom: '20px' }}>
              <label>An Email was sent to you with an OTP enter it here to temporarly log in</label>
            </div>
            <FormInput
              id="otp"
              name="otp"
              onChange={formik.handleChange}
              value={formik.values.otp}
              placeHolder="Enter OTP"
            />
            {formik.errors.otp && formik.touched.otp && <div className="custom__input-error">{formik.errors.otp}</div>}
          </>
        )}
      </Modal>
    </>
  );
};

export default OTPModal;
