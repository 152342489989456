import { Space, Row, Col, Checkbox, Slider, Divider } from 'antd';
import { useSelector } from 'react-redux';
import TextArea from 'antd/es/input/TextArea';

import { LIMIT_SCORE } from '../../consts/LIMIT_SCORE';
import BlockSlider from '../JudgingFormComponents/BlockSlider';
import ScoringGuide from './ScoringGuide';

export default function Overall({ disabled }) {
  const overallStore = useSelector((state) => state.judgingForm.overall);

  return (
    <>
      <div className="form__section w-full">
        <Row className="form__section_title">
          <Col xs={12}>
            <h1>Overall</h1>
          </Col>

          <Col xs={12} style={{ marginBottom: 2 }}>
            <Space align="end" direction="vertical" size={1} className="w-full">
              <Row>
                <input className="score" disabled={disabled} value={overallStore.score.value} type="text" />
                <Space align="end">
                  <p className="text-gray" style={{ marginLeft: 4, textAlign: 'center' }}>
                    Out of {LIMIT_SCORE.overall}
                  </p>
                </Space>
              </Row>
            </Space>
          </Col>
        </Row>

        <Row style={{ marginTop: 15 }}>
          <BlockSlider title="Overall score" score={overallStore.score.value}>
            <Slider
              disabled={disabled}
              value={overallStore.score.value}
              defaultValue={0}
              style={{ marginTop: 18 }}
              min={0}
              max={LIMIT_SCORE.overall}
            />
          </BlockSlider>
        </Row>

        <Divider style={{ marginTop: 20, marginBottom: 40 }} />

        <ScoringGuide />

        <Row className="section-margin-y">
          <BlockSlider title="Stylistic Accuracy" score={overallStore.stylisticAccuracy.value}>
            <Slider
              disabled={disabled}
              value={overallStore.stylisticAccuracy.value}
              defaultValue={0}
              style={{ marginTop: 18 }}
              min={0}
              max={5}
            />
          </BlockSlider>

          <BlockSlider title="Technical Merit" score={overallStore.technicalMerit.value}>
            <Slider
              disabled={disabled}
              value={overallStore.technicalMerit.value}
              defaultValue={0}
              style={{ marginTop: 18 }}
              min={0}
              max={5}
            />
          </BlockSlider>

          <BlockSlider title="Intangibles" score={overallStore.intangibles.value}>
            <Slider
              disabled={disabled}
              value={overallStore.intangibles.value}
              defaultValue={0}
              style={{ marginTop: 18 }}
              min={0}
              max={5}
            />
          </BlockSlider>
        </Row>

        <Row className="section-margin-y">
          <Col xs={24} lg={8}>
            <Space style={{ marginBottom: 16 }}>
              <h3 className="title">Comments</h3>
            </Space>

            <TextArea
              disabled={disabled}
              style={{ marginBottom: 8 }}
              className="w-full"
              rows={4}
              value={overallStore.comments.value}
            />
          </Col>
        </Row>
      </div>
    </>
  );
}
