import { Checkbox, Col, Row, Slider, Space } from 'antd';

import { LIMIT_SCORE } from '../../../consts/LIMIT_SCORE';
import BlockSlider from '../../JudgingFormComponents/BlockSlider';
import ScoringGuide from './ScoringGuide';

export default function Mouthfeel({ mouthfeelStore }) {
  return (
    <>
      <div
        className="form__section w-full tastingnote__section"
        style={{ pageBreakAfter: 'avoid', breakAfter: 'avoid' }}
      >
        <Row className="form__section_title">
          <Col xs={12}>
            <h1>Mouthfeel</h1>
          </Col>

          <Col xs={12} style={{ marginBottom: 2 }}>
            <Space align="end" direction="vertical" size={1} className="w-full">
              <Row>
                <input disabled={true} className="score" value={mouthfeelStore.score.value} type="text" />
                <Space align="end">
                  <p className="text-gray" style={{ marginLeft: 4, textAlign: 'center' }}>
                    Out of {LIMIT_SCORE.mouthfeel}
                  </p>
                </Space>
              </Row>
            </Space>
          </Col>
        </Row>

        <Row style={{ marginTop: 15 }}>
          <BlockSlider title="Mouthfeel score" score={mouthfeelStore.score.value}>
            <Slider
              disabled={true}
              value={mouthfeelStore.score.value}
              defaultValue={0}
              style={{ marginTop: 18 }}
              min={0}
              max={LIMIT_SCORE.mouthfeel}
            />
          </BlockSlider>
        </Row>

        <ScoringGuide />

        <Row className="section-margin-y">
          <BlockSlider title="Body" score={mouthfeelStore.body.value}>
            <Slider
              disabled={true}
              value={mouthfeelStore.body.value}
              defaultValue={0}
              style={{ marginTop: 18 }}
              min={0}
              max={5}
            />
          </BlockSlider>

          <BlockSlider title="Carbonation" score={mouthfeelStore.carbonation.value}>
            <Slider
              disabled={true}
              value={mouthfeelStore.carbonation.value}
              defaultValue={0}
              style={{ marginTop: 18 }}
              min={0}
              max={5}
            />
          </BlockSlider>

          <BlockSlider title="Creaminess" score={mouthfeelStore.creaminess.value}>
            <Slider
              disabled={true}
              value={mouthfeelStore.creaminess.value}
              defaultValue={0}
              style={{ marginTop: 18 }}
              min={0}
              max={5}
            />
          </BlockSlider>
        </Row>

        <Row className="section-margin-y">
          <BlockSlider title="Astringency" score={mouthfeelStore.astringency.value}>
            <Slider
              disabled={true}
              value={mouthfeelStore.astringency.value}
              defaultValue={0}
              style={{ marginTop: 18 }}
              min={0}
              max={5}
            />
          </BlockSlider>

          <Col xs={24} lg={8} className="flex-column" style={{ marginBottom: 16 }}>
            <Space style={{ marginBottom: 16 }}>
              <h3 className="title">Flaws</h3>
            </Space>
            <Row>
              <Col span={24}>
                <Checkbox disabled={true} value="Flat" checked={mouthfeelStore.flaws.flat}>
                  Flat
                </Checkbox>
                <Checkbox disabled={true} value="Gushed" checked={mouthfeelStore.flaws.gushed}>
                  Gushed
                </Checkbox>
                <Checkbox disabled={true} value="Hot" checked={mouthfeelStore.flaws.hot}>
                  Hot
                </Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox disabled={true} value="Harsh" checked={mouthfeelStore.flaws.harsh}>
                  Harsh
                </Checkbox>
                <Checkbox disabled={true} value="Slick" checked={mouthfeelStore.flaws.slick}>
                  Slick
                </Checkbox>
              </Col>
            </Row>
          </Col>

          <Col xs={24} lg={8} className="flex-column">
            <Space style={{ marginBottom: 16 }}>
              <h3 className="title">Finish</h3>
            </Space>

            <Row>
              <Col span={24}>
                <Checkbox disabled={true} value="Cloying" checked={mouthfeelStore.finish.cloying}>
                  Cloying
                </Checkbox>
                <Checkbox disabled={true} value="Sweet" checked={mouthfeelStore.finish.sweet}>
                  Sweet
                </Checkbox>
                <Checkbox disabled={true} value="Medium" checked={mouthfeelStore.finish.medium}>
                  Medium
                </Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox disabled={true} value="Dry" checked={mouthfeelStore.finish.dry}>
                  Dry
                </Checkbox>
                <Checkbox disabled={true} value="Biting" checked={mouthfeelStore.finish.biting}>
                  Biting
                </Checkbox>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="section-margin-y">
          <Col xs={24} lg={8}>
            <Space style={{ marginBottom: 16 }}>
              <h3 className="title">Comments</h3>
            </Space>

            <p style={{ marginBottom: 8 }} disabled={true} className="w-full comments">
              {mouthfeelStore.comments.value}
            </p>
          </Col>
        </Row>

        <Row className="form__section_subtitle">
          <Col xs={24}>
            <h1>Mouthfeel Flaws</h1>
          </Col>
        </Row>

        <div>
          <Row className="section-margin-y">
            <BlockSlider title="Alcoholic/Hot" score={mouthfeelStore.alcoholicHot.value}>
              <Slider
                disabled={true}
                value={mouthfeelStore.alcoholicHot.value}
                defaultValue={0}
                style={{ marginTop: 18 }}
                min={0}
                max={3}
              />
            </BlockSlider>

            <BlockSlider title="Astringent" score={mouthfeelStore.astringent.value}>
              <Slider
                disabled={true}
                value={mouthfeelStore.astringent.value}
                defaultValue={0}
                style={{ marginTop: 18 }}
                min={0}
                max={3}
              />
            </BlockSlider>

            <BlockSlider title="Diacetyl" score={mouthfeelStore.diacetyl.value}>
              <Slider
                disabled={true}
                value={mouthfeelStore.diacetyl.value}
                defaultValue={0}
                style={{ marginTop: 18 }}
                min={0}
                max={3}
              />
            </BlockSlider>
          </Row>

          <Row className="section-margin-y">
            <BlockSlider title="Medicinal" score={mouthfeelStore.medicinal.value}>
              <Slider
                disabled={true}
                value={mouthfeelStore.medicinal.value}
                defaultValue={0}
                style={{ marginTop: 18 }}
                min={0}
                max={3}
              />
            </BlockSlider>

            <BlockSlider title="Metallic" score={mouthfeelStore.metallic.value}>
              <Slider
                disabled={true}
                value={mouthfeelStore.metallic.value}
                defaultValue={0}
                style={{ marginTop: 18 }}
                min={0}
                max={3}
              />
            </BlockSlider>

            <BlockSlider title="Solvent/Fusel" score={mouthfeelStore.solventFusel.value}>
              <Slider
                disabled={true}
                value={mouthfeelStore.solventFusel.value}
                defaultValue={0}
                style={{ marginTop: 18 }}
                min={0}
                max={3}
              />
            </BlockSlider>
          </Row>

          <Row>
            <BlockSlider title="Sour/Acidic" score={mouthfeelStore.sourAcidic.value}>
              <Slider
                disabled={true}
                value={mouthfeelStore.sourAcidic.value}
                defaultValue={0}
                style={{ marginTop: 18 }}
                min={0}
                max={3}
              />
            </BlockSlider>

            <BlockSlider title="Spicy" score={mouthfeelStore.spicy.value}>
              <Slider
                disabled={true}
                value={mouthfeelStore.spicy.value}
                defaultValue={0}
                style={{ marginTop: 18 }}
                min={0}
                max={3}
              />
            </BlockSlider>

            <BlockSlider title="Vinegary" score={mouthfeelStore.vinegary.value}>
              <Slider
                disabled={true}
                defaultValue={0}
                value={mouthfeelStore.vinegary.value}
                style={{ marginTop: 18 }}
                min={0}
                max={3}
              />
            </BlockSlider>
          </Row>
        </div>
      </div>
    </>
  );
}
