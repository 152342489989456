import { useContext, useState, useEffect, useMemo, useCallback } from 'react';
import { Row, Col, Divider, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import Airtable from 'airtable';

import CustomButton from '../CustomButton';
import StripeModal from '../StripeModal';
import EntryItemPage from '../EntryItem';
import Loading from '../Loading';

import { CategoriesContext, BreweryContext } from '../../context';
import { NumberFormat } from '../../common/utils';
import { airtableURL } from '../../FetchURL';
import http from '../../http-common';

import './index.scss';

const ShoppingDataList = ({
  updateShoppingData,
  fetchShoppingData,
  showShoppingData,
  onRemove,
  categories,
  entries,
}) => (
  <>
    {showShoppingData.map((item) => (
      <EntryItemPage
        key={item.id}
        name={item.name}
        updateShoppingData={updateShoppingData}
        fullCategory={item.fullCategory}
        fullCategoryValue={item.fullCategoryValue}
        pour={item.pour}
        boty={item.boty}
        record={item.id}
        description={item.description}
        categories={categories}
        price={entries.price}
        onDelete={() => onRemove(item.id)}
        fetchShoppingData={fetchShoppingData}
      />
    ))}
  </>
);

const ShoppingCart = (props) => {
  const navigate = useNavigate();
  const [brewery, setBrewery] = useContext(BreweryContext);
  const [categories, setCategories] = useContext(CategoriesContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shoppingData, setShoppingData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [payEntryList, setPayEntryList] = useState([]);

  const backToEntry = () => {
    props.handleShoppingCard();
  };

  const updateShoppingData = (id, newData) => {
    const index = shoppingData.findIndex((item) => item.id === id);
    const updatedData = [...shoppingData];

    updatedData[index] = { ...updatedData[index], ...newData };

    setShoppingData(updatedData);
  };

  const goToRegisterEntry = () => {
    navigate(`/register-entry/${props.eventId}`);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const setPayData = (list) => {
    setPayEntryList([]);
    for (let i = 0; i < list.length; i++) setPayEntryList((t) => [...t, list[i].id]);
  };

  const removeList = (record) => {
    setLoading(true);
    http
      .delete(airtableURL + `/entry/${record}`)
      .then((res) => {
        const newList = shoppingData.filter((item) => item.id !== record);
        setPayData(newList);
        setShoppingData(newList);
        setLoading(false);
        message.success('Delete Success');
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          error?.response?.date?.error || 'Something went wrong while attempting to delete please try again'
        );
      });
  };

  const getTotalAmount = useMemo(() => {
    return shoppingData.length * props.entries.price;
  }, [shoppingData, props]);

  const fetchShoppingData = useCallback(async () => {
    try {
      setLoading(true);
      setShoppingData([]);

      const res = await http.post(airtableURL + '/get-shopping-data', {
        eventName: props.entries.eventName,
        breweryID: brewery.fields['brewery-id'],
      });

      if (res.status !== 200) return setLoading(false);
      setShoppingData(res.data);
      setPayData(res.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('There was an error fetch auth', error);
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchShoppingData();
  }, [props.update, props.entries, brewery, fetchShoppingData]);

  return (
    <div className="custom-container">
      <div className="custom-label__header d-flex justify-between">
        <label>Shopping Cart</label>
      </div>

      <div className="d-flex d-flex justify-between entries-label" style={{ marginTop: 20 }}>
        <div></div>
        <label>This year each entry costs ${props.entries.price}+HST</label>
      </div>

      <div className="w-100 shopping-cart">
        <div className="d-flex justify-between">
          <label className="shopping-cart__header">Entry</label>
        </div>

        <Divider className="shopping-cart__divider" />
        {loading ? (
          <div style={{ height: '250px' }}>
            <Loading />
          </div>
        ) : (
          <ShoppingDataList
            fetchShoppingData={fetchShoppingData}
            updateShoppingData={updateShoppingData}
            showShoppingData={shoppingData}
            onRemove={removeList}
            categories={categories}
            entries={props.entries}
          />
        )}
        {/* <div className="shopping-cart__add-entry text-center">
          <label
            className="shopping-cart__add-entry__button pointer"
            onClick={() => {
              navigate('/register-entry');
            }}
          >
            + ADD AN ENTRY
          </label>
        </div> */}
        {/* <Divider className="shopping-cart__divider" /> */}
        <div className="d-flex justify-between">
          <label className="shopping-cart__footer-label">Total</label>
          <label className="shopping-cart__footer-price">${NumberFormat(getTotalAmount)}</label>
        </div>
      </div>
      <Row className="d-flex" justify="end" gutter={16}>
        <Col>
          <CustomButton
            primary=""
            onClick={() => {
              backToEntry();
            }}
            label="Cancel"
          />
        </Col>
        <Col className="d-flex">
          <form
            action={`${process.env.REACT_APP_BACKEND_BASE_URL}/stripe/create-entry-checkout-session`}
            // action={`http://localhost:5000/stripe/create-entry-checkout-session`}
            method="POST"
            target="_blank"
          >
            {payEntryList.map((value) => {
              return <input name="entryList" value={value} hidden={true} />;
            })}
            <CustomButton htmlType="submit" primary="primary" label="Checkout" disabled={shoppingData?.length <= 0} />
          </form>
        </Col>
      </Row>
      {/* <StripeModal
        email={brewery.fields['Public Email']}
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        totalAmount={getTotalAmount}
        entryList={payEntryList}
      /> */}
    </div>
  );
};

export default ShoppingCart;
