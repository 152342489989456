import { ReloadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Col, Row, Space, Table, Tooltip } from 'antd';
import { useContext, useEffect, useState } from 'react';

import { BreweryContext } from '../../context';
import { airtableURL } from '../../FetchURL';
import http from '../../http-common';

export default function SavedEntries({ setFormValues, marginTop }) {
  const [unhideLoading, setUnhideLoading] = useState(false);
  const [entries, setEntries] = useState([]);
  const [loading, setLoading] = useState(false);

  const [brewery] = useContext(BreweryContext);
  const [query, setQuery] = useSearchParams();
  const navigate = useNavigate();

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Pour',
      dataIndex: 'pour',
      key: 'pour',
    },
    {
      title: 'Category',
      dataIndex: 'fullCategory',
      key: 'category',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={() =>
              setFormValues
                ? setQuery({ prefilled: record.id })
                : navigate(`/register-entry/${process.env.REACT_APP_EVENT_ID}/?prefilled=${record.id}`)
            }
          >
            Re-enter beer
          </Button>
          <Button
            onClick={() => {
              hideEntry(record.id);
            }}
          >
            Hide
          </Button>
        </Space>
      ),
    },
  ];
  const fetchEntries = () => {
    if (!brewery.fields) return;
    setLoading(true);
    http
      .post(airtableURL + '/get-entries-data', { breweryID: brewery.fields['brewery-id'], saved: true })
      .then((response) => {
        setEntries(response.data);
        console.log(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  useEffect(fetchEntries, [brewery]);

  const hideEntry = (entryId) => {
    setLoading(true);
    http
      .put(airtableURL + `/hide-saved-entry/${entryId}`)
      .then((response) => {
        fetchEntries();
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const unhideAllEntries = () => {
    setUnhideLoading(true);
    http
      .post(airtableURL + '/unhide-saved-entries', { breweryID: brewery.fields['brewery-id'] })
      .then(() => {
        setUnhideLoading(false);
        fetchEntries();
      })
      .catch((error) => {
        console.log(error);
        setUnhideLoading(false);
      });
  };

  useEffect(() => {
    if (!(query && entries && setFormValues)) return;
    if (!query.get('prefilled')) return;
    const record = entries.find((value) => value.id === query.get('prefilled'));
    if (record) setFormValues(record);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, entries]);

  return (
    <div className="custom-container">
      <Row justify="space-between" align="middle" style={{ marginTop: marginTop || 0, marginBottom: 10 }}>
        <Col style={{ margin: 0 }}>
          <Space>
            <div className="custom-label__header" style={{ margin: 0 }}>
              <label>Saved Entries</label>
            </div>
            <Tooltip
              placement="topLeft"
              title="You can click on 'Quick Fill' to populate the registration form with the data from a previously registered entry. This feature allows you to conveniently import your saved information and make any necessary edits before adding it to your cart."
            >
              <InfoCircleOutlined />
            </Tooltip>
          </Space>
        </Col>
        <Col>
          <Button icon=<ReloadOutlined /> loading={unhideLoading} onClick={unhideAllEntries}>
            Reset Hidden
          </Button>
        </Col>
      </Row>

      <Table
        rowKey="id"
        columns={columns}
        dataSource={entries}
        loading={loading}
        expandable={{
          expandedRowRender: (record) => (
            <p
              style={{
                margin: 0,
              }}
            >
              {record.description}
            </p>
          ),
          rowExpandable: (record) => record.description,
        }}
      />
    </div>
  );
}
